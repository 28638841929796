import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SellerProductsService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  addSellerProduct(data){
    return this.httpMasterService.postService('sellers/sellerProducts', data);
  }

  addSellerProductFarm(data){
    return this.httpMasterService.postService('sellers/sellerProductFarms', data);
  }

  updateSellerProductFarm(data, id){
    return this.httpMasterService.patchService(`sellers/sellerProductFarms/${id}`, data);
  }

  deleteSellerProductFarm(id){
    return this.httpMasterService.deleteService(`sellers/sellerProductFarms/${id}`);
  }

  addSellerProductContact(data){
    return this.httpMasterService.postService('sellers/sellerProductContacts', data);
  }

  updateSellerProductContact(data, id){
    return this.httpMasterService.patchService(`sellers/sellerProductContacts/${id}`, data);
  }

  deleteSellerProductContact(id){
    return this.httpMasterService.deleteService(`sellers/sellerProductContacts/${id}`);
  }

  addSellerProductSupplyLocation(data){
    return this.httpMasterService.postService('sellers/sellerProductSupplyLocations', data);
  }

  updateSellerProductSupplyLocation(data, id){
    return this.httpMasterService.patchService(`sellers/sellerProductSupplyLocations/${id}`, data);
  }

  deleteSellerProductSupplyLocation(id){
    return this.httpMasterService.deleteService(`sellers/sellerProductSupplyLocations/${id}`);
  }

  addSellerProductBrand(data){
    return this.httpMasterService.postService('sellers/sellerProductBrands', data);
  }

  deleteSellerProductBrand(id){
    return this.httpMasterService.deleteService(`sellers/sellerProductBrands/${id}`);
  }

  addSellerProductCFPCertification(data){
    return this.httpMasterService.postService('sellers/sellerProductCFPCertifications', data);
  }

  deleteSellerProductCFPCertification(id){
    return this.httpMasterService.deleteService(`sellers/sellerProductCFPCertifications/${id}`);
  }

  addSellerProductOrganicAccreditation(data){
    return this.httpMasterService.postService('sellers/sellerProductOrganicAccreditations', data);
  }

  deleteSellerProductOrganicAccreditation(id){
    return this.httpMasterService.deleteService(`sellers/sellerProductOrganicAccreditations/${id}`);
  }

  getSellerContactsList(id){
    return this.httpMasterService.getService(`sellers/contactsListByProduct/${id}`);
  }

  getSellerProductDetails(id){
    return this.httpMasterService.getService(`sellers/productDetails/${id}`);
  }

  productSubmitForAdminApproval(id){
    return this.httpMasterService.patchService(`sellers/sellerProducts/submitForAdminApproval/${id}`, {});
  }
}
