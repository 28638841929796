import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  getBuyerDetails(id){
    return this.httpMasterService.getService(`buyers/${id}`)
  }

  getAllSellersSupplyLocations(){
    return this.httpMasterService.getService(`buyers/allSellersSupplyLocations`)
  }

  getFilteredSellersSupplyLocations(data){
    return this.httpMasterService.postService(`buyers/sellerSupplyLocationsByFilter`, data)
  }

  submitBuyerRfpForm(data){
    return this.httpMasterService.postService(`buyers/rfps`, data)
  }
}
