import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { BuyerService } from 'src/app/services/buyer.service';

@Component({
  selector: 'app-buyer-approvals',
  templateUrl: './buyer-approvals.component.html',
  styleUrls: ['./buyer-approvals.component.css']
})
export class BuyerApprovalsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild("approveCloseBtn") approveCloseBtn: ElementRef;
  @ViewChild("rejectCloseBtn") rejectCloseBtn: ElementRef;
  @ViewChild("pauseModelCloseBtn") pauseModelCloseBtn: ElementRef;
  @ViewChild("resumeModelCloseBtn") resumeModelCloseBtn: ElementRef;
  @ViewChild("deleteModelCloseBtn") deleteModelCloseBtn: ElementRef;

  constructor(
    private toastr: ToastrService,
    private adminService: AdminService,
    private buyerService: BuyerService
  ) {}

  ngOnInit() {
    this.dtOptions = this.getDatatableOptions();
    this.getApprovedBuyers();
  }

  buyersList: any = [];
  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blftip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub approved Buyers'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub approved Buyers'
        }
      ]
    };
  }

  getApprovedBuyers(){
    this.adminService.getApprovedBuyers().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.buyersList = resp.data;
    })
  }

  buyerDetails: any = {};
  viewBuyerDetails(id) {
    this.buyerService.getBuyerDetails(id).subscribe(
      (resp: any) => {
        this.buyerDetails = resp.data;
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  currentBuyerId: any;
  storeCurrentBuyerId(id) {
    this.currentBuyerId = id;
  }

  isSubmitBtnDisabled: boolean = false;
  removeCurrentBuyerId() {
    this.currentBuyerId = "";
    this.pauseModelCloseBtn.nativeElement.click();
    this.resumeModelCloseBtn.nativeElement.click();
    this.deleteModelCloseBtn.nativeElement.click();
  }

  pauseBuyer(){
    this.isSubmitBtnDisabled = true;
    const data = {
      id: this.currentBuyerId,
      status: "INACTIVE"
    };
    this.adminService.pauseBuyer(data).subscribe(
      (resp: any) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.success("Buyer services paused successfully.");
        this.removeCurrentBuyerId();
        this.getApprovedBuyers();
      },
      (err) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  resumeBuyer(){
    this.isSubmitBtnDisabled = true;
    const data = {
      id: this.currentBuyerId,
      status: "ACTIVE"
    };
    this.adminService.pauseBuyer(data).subscribe(
      (resp: any) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.success("Buyer services resumed successfully.");
        this.removeCurrentBuyerId();
        this.getApprovedBuyers();
      },
      (err) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  deleteBuyer(){
    this.isSubmitBtnDisabled = true;
    const data = {
      id: this.currentBuyerId
    };
    this.adminService.deleteBuyer(data).subscribe(
      (resp: any) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.success("Buyer deleted successfully.");
        this.removeCurrentBuyerId();
        this.getApprovedBuyers();
      },
      (err) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

}
