import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SellerProductsService } from "./../../services/seller-products.service";
import { MasterDataService } from "./../../services/master-data.service";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { environment } from "src/environments/environment";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-seller-products",
  templateUrl: "./seller-products.component.html",
  styleUrls: ["./seller-products.component.css"],
})
export class SellerProductsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild("addProductCloseBtn") addProductCloseBtn: ElementRef;

  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sellerProductsService: SellerProductsService,
    private masterDataService: MasterDataService,
    private cookiesService: CookieService,
    private router: Router
  ) {}

  loggedinUser: any = {};
  ngOnInit() {
    this.loggedinUser = this.cookiesService.getObject("user");
    this.getCategories();
    this.dtOptions = this.getDatatableOptions();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  productPrimaryInfoModel: any = {
    is_cage_produce: false,
    is_cfp_certified: false,
    is_cage_free_convertible: false,
    is_organic_produce: false,
  };
  isCreateBtnDisabled = false;
  createProduct() {
    this.productPrimaryInfoModel.user_id = this.loggedinUser.id;
    this.isCreateBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .addSellerProduct(this.productPrimaryInfoModel)
      .subscribe(
        (resp: any) => {
          this.isCreateBtnDisabled = false;
          this.spinner.hide();
          this.resetproductPrimaryInfoModel();
          this.toastr.success(resp.message);
          this.addProductCloseBtn.nativeElement.click();
          this.navigateToProductDetails(resp.productId);
        },
        (err: HttpErrorResponse) => {
          this.isCreateBtnDisabled = false;
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
  }

  resetproductPrimaryInfoModel() {
    this.subcategoriesList = [];
    this.productPrimaryInfoModel = {
      is_cage_produce: false,
      is_cfp_certified: false,
      is_cage_free_convertible: false,
      is_organic_produce: false,
    };
  }

  categoriesList: any = [];
  getCategories() {
    this.masterDataService.getCategories().subscribe(
      (resp: any) => {
        this.categoriesList = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  subcategoriesList: any = [];
  getSubcategoriesByCategory() {
    this.spinner.show();
    this.masterDataService
      .getSubcategoriesByCategory(this.productPrimaryInfoModel.category_id)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.subcategoriesList = resp.data;
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
  }

  getSubcategories() {
    this.productPrimaryInfoModel.sub_category_id = undefined;
    this.getSubcategoriesByCategory();
  }

  changePreviousSelectedValues() {
    this.productPrimaryInfoModel.is_cage_free_convertible = false;
    this.productPrimaryInfoModel.is_cfp_certified = false;
  }

  productsList: any = [];
  getDatatableOptions() {
    return {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      retrieve: true,
      order: [[0, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        this.httpClient
          .post<DataTablesResponse>(
            `${environment.base_url}sellers/productsList`,
            Object.assign(dataTablesParameters, {
              user_id: this.loggedinUser.id,
            }),
            {}
          )
          .subscribe(
            (resp: any) => {
              this.productsList = resp.data;
              callback({
                recordsTotal: resp.total,
                recordsFiltered: resp.total,
                data: [],
              });
            },
            (err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.router.navigate["/login"];
              } else if (err.status === 500) {
                this.router.navigate["/error"];
              }
            }
          );
      },
      columns: this.getColumnsData(),
    };
  }

  getColumnsData() {
    return [
      { title: "S.No", data: "" },
      { title: "Category", data: "catgoryName", name: "categories.name" },
      {
        title: "Subcategory",
        data: "subCategoryName",
        name: "sub_categories.name",
      },
      {
        title: "Annual quantity",
        data: "annual_quantity",
        name: "annual_quantity",
      },
      { title: "Is cage produce", data: "", name: "", orderable: false },
      {
        title: "Is cage free production certified",
        data: "",
        name: "",
        orderable: false,
      },
      {
        title: "Is cage free convertible",
        data: "",
        name: "",
        orderable: false,
      },
      { title: "Is organic produce", data: "", name: "", orderable: false },
      { title: "Status", data: "", name: "", orderable: false },
      { title: "Actions", data: "", name: "", orderable: false },
    ];
  }

  navigateToProductDetails(id){
    this.router.navigate(["seller/productDetails"], { queryParams: { productId: id } });
  }

  productIdToSumbit: any;
  storeProductId(id){
    this.productIdToSumbit = id;
  }

  removeProductId(){
    this.submitCloseBtn.nativeElement.click();
    this.productIdToSumbit = "";
  }

  @ViewChild("submitCloseBtn") submitCloseBtn: ElementRef;
  isProductSubmitBtnDisabled = false;
  productSubmitForAdminApproval(){
    this.isProductSubmitBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService.productSubmitForAdminApproval(this.productIdToSumbit).subscribe((resp: any)=>{
      this.isProductSubmitBtnDisabled = false;
      this.spinner.hide();
      this.removeProductId();
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }, (err: HttpErrorResponse)=>{
      this.isProductSubmitBtnDisabled = false;
      this.spinner.hide();
    })
  }
}
