import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { MasterDataService } from "./../../services/master-data.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { BuyerService } from "src/app/services/buyer.service";
import { CookieService } from "angular2-cookie/services/cookies.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-find-seller",
  templateUrl: "./find-seller.component.html",
  styleUrls: ["./find-seller.component.css"],
})
export class FindSellerComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  lat: number = 51.4934;
  lng: number = 0.0098;
  mapZoomLevel = 2;
  constructor(
    private masterDataService: MasterDataService,
    private router: Router,
    private toastr: ToastrService,
    private buyerService: BuyerService,
    private cookiesService: CookieService,
    private route: ActivatedRoute
  ) {}

  params: any = {};
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (
        params.selectedBusinessList ||
        params.selectedCountry ||
        params.selectedCity
      ) {
        this.params = params;
        if (params.selectedBusinessList) {
          this.selectedBusinessList = JSON.parse(params.selectedBusinessList);
        }
        if (params.selectedCountry) {
          this.selectedCountry = params.selectedCountry;
        }
        if (params.selectedCity) {
          this.selectedCity = +params.selectedCity;
        }
        if (params.selectedCountry) {
          this.getCities();
        }
        this.getFilteredSellerSupplyLocations();
      }
    });
    if (
      !this.params.selectedBusinessList &&
      !this.params.selectedCountry &&
      !this.params.selectedCity
    ) {
      this.getAllSellerSupplyLocations();
    }
    this.getCountries();
    this.dtOptions = this.getDatatableOptions();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  resetFilters() {
    this.selectedBusinessList = [];
    this.selectedCountry = "";
    this.selectedCity = "";
    this.cities = [];
    this.mapZoomLevel = 2;
    this.getAllSellerSupplyLocations();
    this.listOfSellers = [];
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
    this.router.navigate(["/buyer/findSeller"]);
  }

  getDatatableOptions() {
    return {
      pagingType: "full_numbers",
      pageLength: 10,
      buttons: ["print", "excel", "pdf"],
      dom: "Blfrtip",
    };
  }

  showSellerPreview(userId) {
    if (this.selectedBusinessList.length > 0) {
      this.cookiesService.putObject(
        "selectedBusinessList",
        this.selectedBusinessList
      );
    }
    if (this.selectedCountry) {
      this.cookiesService.put("selectedCountry", this.selectedCountry);
    }
    if (this.selectedCity) {
      this.cookiesService.put("selectedCity", this.selectedCity);
    }
    this.router.navigate(["/buyer/sellerDetails"], {
      queryParams: { sellerId: userId },
    });
  }

  countries: any = [];
  selectedCountry: any;
  getCountries() {
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.countries = data.data.map((country) => {
          return {
            label: country.country,
            value: country.country_code,
          };
        });
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  supplyLocations: any = [];
  selectedBusinessList: any = [];
  getAllSellerSupplyLocations() {
    this.buyerService.getAllSellersSupplyLocations().subscribe(
      (data: any) => {
        this.supplyLocations = data.data;
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  natureOfBusinessList = [
    { label: "Cage free processed eggs", value: 2 },
    { label: "Cage free shell eggs", value: 1 },
  ];

  getFilteredSellerSupplyLocations() {
    const dataToSend = {
      businessList: this.selectedBusinessList,
      country: this.selectedCountry,
      city: this.selectedCity,
    };
    this.buyerService.getFilteredSellersSupplyLocations(dataToSend).subscribe(
      (data: any) => {
        this.supplyLocations = data.data;
        if (this.selectedCity) {
          if (data.data.length > 0) {
            this.listOfSellers = data.data[0].sellers;
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.listOfSellers = [];
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  cities: any = [];
  selectedCity: any;
  onCountrySelecet() {
    this.selectedCity = null;
    this.getCities();
  }
  listOfSellers: any = [];
  onMarkerClick(location) {
    this.selectedCity = location.city;
    this.selectedCountry = location.country;
    this.getCities();
    this.getFilteredSellerSupplyLocations();
    // this.listOfSellers = location.sellers;
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    //   this.dtTrigger.next();
    // });
  }
  getCities() {
    this.masterDataService.getCities(this.selectedCountry).subscribe(
      (data: any) => {
        this.cities = data.data.map((city) => {
          return {
            label: city.city,
            value: city.id,
          };
        });
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }
}
