import { Injectable } from "@angular/core";
import { CookieService } from "angular2-cookie/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private cookiesService: CookieService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.cookiesService.get("gfp_jwt_token");
    if (token) {
      const modifiedRequest = req.clone({
        headers: req.headers.append("Authorization", `Bearer ${token}`),
      });
      return next.handle(modifiedRequest);
    } else {
      return next.handle(req);
    }
  }
}
