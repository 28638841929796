import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "./../../services/login.service";
import { Router } from "@angular/router";
import { CookieService } from "angular2-cookie/core";
import { AuthService } from "./../../services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private loginService: LoginService,
    private router: Router,
    private cookiesService: CookieService,
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      const user = this.cookiesService.getObject("user");
      this.navigateAfterLogin(user);
    }
  }

  loginModel: any = {};
  registeredEmail: string;
  @ViewChild("forgotPasswordCloseBtn") forgotPasswordCloseBtn: ElementRef;

  isButtonDisabled = false;
  login() {
    this.spinner.show();
    this.isButtonDisabled = true;
    this.loginService.login(this.loginModel).subscribe(
      (resp: any) => {
        this.spinner.hide();
        if (resp.user.role === 1) {
          if (resp.user.approval_status === 2) {
            this.toastr.success("User logged in successfully.");
            this.cookiesService.put("gfp_jwt_token", resp.token);
            this.cookiesService.putObject("user", resp.user);
            this.router.navigate(["/buyer/findSeller"]);
          } else {
            this.router.navigate(["waiting-for-approval"]);
          }
        } else {
          this.toastr.success("User logged in successfully.");
          this.cookiesService.put("gfp_jwt_token", resp.token);
          this.cookiesService.putObject("user", resp.user);
          if (resp.user.role === 2) {
            this.router.navigate(["/seller/productInfo"]);
          } else if (resp.user.role === 3) {
            this.router.navigate(["/admin/buyer-rfp"]);
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.isButtonDisabled = false;
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  navigateAfterLogin(user) {
    if (user.role === 1) {
      if (user.approval_status === 2) {
        this.router.navigate(["/buyer/findSeller"]);
      }
    } else {
      if (user.role === 2) {
        this.router.navigate(["/seller/productInfo"]);
      } else if (user.role === 3) {
        this.router.navigate(["/admin/buyer-rfp"]);
      }
    }
  }

  isForgotPasswordSubmitBtnDisabled = false;
  submitForgotPassword(){
    this.spinner.show();
    this.isForgotPasswordSubmitBtnDisabled = true;
    this.loginService.forgotPassword({email: this.registeredEmail}).subscribe((resp: any)=>{
      this.toastr.success(resp.message);
      this.spinner.hide();
      this.isForgotPasswordSubmitBtnDisabled = false;
      this.forgotPasswordCloseBtn.nativeElement.click();
      this.registeredEmail = "";
    }, (err: HttpErrorResponse)=>{
      this.spinner.hide();
      this.isForgotPasswordSubmitBtnDisabled = false;
      this.toastr.error(err.error.message);
    })
  }

  clear(){

  }
}
