import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { SellersService } from "./../../services/sellers.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { MasterDataService } from "src/app/services/master-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CountryPhoneCodesService } from "./../../services/country-phone-codes.service";
import { NgForm } from "@angular/forms";
import _ from "lodash";
import { UserProfileService } from "./../../services/user-profile.service";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { Router } from "@angular/router";
import { Http } from "@angular/http";

@Component({
  selector: "app-seller-product-info",
  templateUrl: "./seller-product-info.component.html",
  styleUrls: ["./seller-product-info.component.css"],
})
export class SellerProductInfoComponent implements OnInit {
  showExtraOrImages = false;
  orimageLength: any;
  constructor(
    private sellerService: SellersService,
    private toastr: ToastrService,
    private masterDataService: MasterDataService,
    private spinner: NgxSpinnerService,
    private countryCodesService: CountryPhoneCodesService,
    private userProfileService: UserProfileService,
    private cookiesService: CookieService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loggedInUser = this.cookiesService.getObject("user");
    this.getCompanyInfo();
    this.getNatureOfBusinessList();
    this.getNatureOfBusinessListSubTypes();
    this.getCountries();
    this.getSellerSupplyLocations();
    this.getOrgImages();
    this.getCFPSystems();
    this.getSellerStatusInfo();
  }

  @ViewChildren("certificationFileUploadEl")
  certificationFileUploadEl: QueryList<ElementRef>;
  @ViewChildren("brandImageUploadEl")
  brandImageUploadEl: QueryList<ElementRef>;
  loggedInUser: any = {};
  companyInfoModel: any = {};
  farmModel: any = {};
  selectedBusinessList: any = [];
  selectedCountry: any = {};
  isCompanyInfoFormEditable = false;
  @ViewChild("createSupplyLocationCloseBtn")
  createSupplyLocationCloseBtn: ElementRef;
  @ViewChild("supplyLocationDeleteModalCloseBtn")
  supplyLocationDeleteModalCloseBtn: ElementRef;
  emailRegEx = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  getCompanyInfo() {
    this.sellerService.getCompanyInfo().subscribe(
      (resp: any) => {
        this.companyInfoModel = { ...resp.data.companyInfo };
        console.log(
          "this.companyInfoModel.profile_image",
          this.companyInfoModel.profile_image
        );
        if (resp.data.businessListIds.indexOf(3) >= 0) {
          this.companyInfoModel.nature_of_business_list_otherName = resp.data.sellerBusinessList.filter(
            (business) => business.seller_business_list_id === 3
          )[0].other_name;
        }
        if (
          resp.data.businessListSubTypeIds &&
          resp.data.businessListSubTypeIds.indexOf(4) >= 0
        ) {
          this.companyInfoModel.nature_of_business_list_sub_type_otherName = resp.data.sellerBusinessListSubTypes.filter(
            (business) => business.seller_business_list_sub_type_id === 4
          )[0].other_name;
        }
        this.selectedBusinessList = [...resp.data.businessListIds];
        if (resp.data.businessListSubTypeIds) {
          this.selectedBusinessListSubTypes = resp.data.businessListSubTypeIds
            ? [...resp.data.businessListSubTypeIds]
            : [];
        }
        this.createProductionCapacitiesArray({
          businessListIds: [...resp.data.businessListIds],
          businessListSubTypeIds: resp.data.businessListSubTypeIds
            ? [...resp.data.businessListSubTypeIds]
            : [],
          sellerBusinessList: [...resp.data.sellerBusinessList],
          sellerBusinessListSubTypes: resp.data.sellerBusinessListSubTypes
            ? [...resp.data.sellerBusinessListSubTypes]
            : [],
        });
        // To get Country, States, Cities
        this.selectedCountry = {
          country_code: this.companyInfoModel.country,
          country: this.companyInfoModel.countryName,
        };
        this.selectedCity = {
          id: this.companyInfoModel.city,
          city: `${this.companyInfoModel.cityName} (${this.companyInfoModel.state})`,
        };
        this.getSellerBusinessInfo();
        this.getCountryPhoneCode();
        this.getCities();
        this.isCountrySelected = true;
        this.isCitySelected = true;
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.message);
      }
    );
  }

  getSellerBusinessInfo() {
    this.sellerService.getSellerBusinessInfo().subscribe((resp: any) => {
      if (resp.data.isBusinessInfoFilled) {
        this.isBusinessInfoAlreadyAdded = true;
        const prodCapacities = [...this.businessInfoModel.productionCapacities];
        const newCertifications = [...this.businessInfoModel.newCertifications];
        const newBrands = [...this.businessInfoModel.newBrands];
        prodCapacities.map((capacity) => {
          resp.data.businessInfo.productionCapacities.map((inner) => {
            if (
              inner.businessId === capacity.businessId &&
              inner.typeId === capacity.typeId
            ) {
              capacity.capacity = inner.capacity;
              capacity.units = inner.units;
            }
          });
        });
        this.businessInfoModel = resp.data.businessInfo;
        this.businessInfoModel.productionCapacities = prodCapacities;
        if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
          this.businessInfoModel.certifications = [
            ...this.businessInfoModel.oldCertifications,
          ];
        } else {
          this.businessInfoModel.certifications = [
            ...this.businessInfoModel.newCertifications,
          ];
        }
        if (this.businessInfoModel.businessInfo.is_having_brands) {
          this.businessInfoModel.brands = [...this.businessInfoModel.oldBrands];
        } else {
          this.businessInfoModel.brands = [...this.businessInfoModel.newBrands];
        }
        this.businessInfoModel.newBrandFiles = [];
        this.businessInfoModel.newCertificationFiles = [];
      } else {
        this.isBusinessInfoAlreadyAdded = false;
        this.businessInfoModel.certifications = [
          ...this.businessInfoModel.newCertifications,
        ];
        this.businessInfoModel.brands = [...this.businessInfoModel.newBrands];
      }
    });
  }

  // Update and edit certifications data start

  uploadCertificationImage(event: any, certification, i) {
    if (event.target.files && event.target.files[0]) {
      certification.isFileUploaded = true;
      certification.fileName = event.target.files[0].name;
      const fileIndex = i - this.businessInfoModel.oldCertifications.length;
      this.businessInfoModel.newCertificationFiles[fileIndex] =
        event.target.files[0];
      if (event.target.files[0].name.split(".").pop() !== "pdf") {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          certification.localFile = (<FileReader>event.target).result;
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        certification.localFile = event.target.files[0].name;
      }
      if (
        !certification.name ||
        !certification.name.trim() || certification.isDuplicate ||
        !certification.isFileUploaded
      ) {
        certification.hasError = true;
        return true;
      } else {
        certification.hasError = false;
      }
    }
  }

  removeCertificationFile(certification, i) {
    this.certificationFileUploadEl.map((el, index) => {
      if (i === index) {
        el.nativeElement.value = "";
        if (certification.isNew) {
          const fileIndex = i - this.businessInfoModel.oldCertifications.length;
          this.businessInfoModel.newCertificationFiles[fileIndex] = "";
        } else {
          this.businessInfoModel.filesToDelete.push(certification.localFile);
          const newCertification = this.businessInfoModel.oldCertifications.splice(
            i,
            1
          )[0];
          newCertification.isNew = true;
          this.businessInfoModel.newCertifications.push(newCertification);
          this.businessInfoModel.certifications = [
            ...this.businessInfoModel.oldCertifications,
            ...this.businessInfoModel.newCertifications,
          ];
        }
      }
    });
    certification.localFile = "";
    certification.isFileUploaded = false;
    certification.hasError = true;
    console.log(this.businessInfoModel);
  }

  onCertificationDelete(certification, i) {
    if (certification.isNew) {
      const fileIndex = i - this.businessInfoModel.oldCertifications.length;
      this.businessInfoModel.newCertificationFiles.splice(fileIndex, 1);
      this.businessInfoModel.newCertifications.splice(fileIndex, 1);
      this.businessInfoModel.certifications = [
        ...this.businessInfoModel.oldCertifications,
        ...this.businessInfoModel.newCertifications,
      ];
    } else {
      this.businessInfoModel.filesToDelete.push(certification.localFile);
      this.businessInfoModel.oldCertifications.splice(i, 1);
      this.businessInfoModel.certifications = [
        ...this.businessInfoModel.oldCertifications,
        ...this.businessInfoModel.newCertifications,
      ];
    }
    console.log(this.businessInfoModel);
  }

  addCertification() {
    if (this.businessInfoModel.newCertifications.length === 0) {
      this.businessInfoModel.newCertifications.push({ name: "", isNew: true });
      this.businessInfoModel.certifications = [
        ...this.businessInfoModel.oldCertifications,
        ...this.businessInfoModel.newCertifications,
      ];
    } else {
      const incompleteBrands = this.businessInfoModel.newCertifications.filter(
        (certification) => {
          if (
            !certification.name ||
            !certification.name.trim() ||
            !certification.isFileUploaded
          ) {
            certification.hasError = true;
            return true;
          }
          certification.hasError = false;
        }
      );
      if (incompleteBrands.length > 0) {
        this.toastr.error(
          "Please fill all the details of previous certifications"
        );
      } else {
        this.businessInfoModel.newCertifications.push({
          name: "",
          isNew: true,
        });
        this.businessInfoModel.certifications = [
          ...this.businessInfoModel.oldCertifications,
          ...this.businessInfoModel.newCertifications,
        ];
      }
    }
  }

  checkForErrorInCertificationData(certification, index) {
    if (
      !certification.name ||
      !certification.name.trim() ||
      (!certification.isFileUploaded && certification.isNew)
    ) {
      certification.hasError = true;
    } else {
      certification.hasError = false;
    }
    const allCertifications = this.businessInfoModel.certifications.map((cer, i)=>{
      if(index !== i)
      return cer.name.toLowerCase()
    });
    if(allCertifications.indexOf(certification.name.toLowerCase()) >= 0){
      certification.isDuplicate = true;
      certification.hasError = true;
    }else{
      certification.isDuplicate = false;
      certification.hasError = false;
    }
  }

  // Update and edit certifications data end

  // Update and edit brand data start

  uploadBrandImage(event: any, brand, i) {
    if (event.target.files && event.target.files[0]) {
      brand.isFileUploaded = true;
      const fileIndex = i - this.businessInfoModel.oldBrands.length;
      this.businessInfoModel.newBrandFiles[fileIndex] = event.target.files[0];
      brand.fileName = event.target.files[0].name;
      if (event.target.files[0].name.split(".").pop() !== "pdf") {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          brand.localFile = (<FileReader>event.target).result;
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        brand.localFile = event.target.files[0].name;
      }
      if (!brand.name || !brand.name.trim() || brand.isDuplicate) {
        brand.hasError = true;
        return true;
      } else {
        brand.hasError = false;
      }
    }
  }

  removeBrandFile(brand, i) {
    this.brandImageUploadEl.map((el, index) => {
      if (i === index) {
        el.nativeElement.value = "";
        if (brand.isNew) {
          const fileIndex = i - this.businessInfoModel.oldBrands.length;
          this.businessInfoModel.newBrandFiles[fileIndex] = "";
        } else {
          this.businessInfoModel.filesToDelete.push(brand.localFile);
          const newBrand = this.businessInfoModel.oldBrands.splice(i, 1)[0];
          newBrand.isNew = true;
          this.businessInfoModel.newBrands.push(newBrand);
          this.businessInfoModel.brands = [
            ...this.businessInfoModel.oldBrands,
            ...this.businessInfoModel.newBrands,
          ];
        }
      }
    });
    brand.localFile = "";
    brand.isFileUploaded = false;
    // brand.hasError = true;
    console.log(this.businessInfoModel);
  }

  onBrandDelete(brand, i) {
    if (brand.isNew) {
      const fileIndex = i - this.businessInfoModel.oldBrands.length;
      this.businessInfoModel.newBrandFiles.splice(fileIndex, 1);
      this.businessInfoModel.newBrands.splice(fileIndex, 1);
      this.businessInfoModel.brands = [
        ...this.businessInfoModel.oldBrands,
        ...this.businessInfoModel.newBrands,
      ];
    } else {
      this.businessInfoModel.filesToDelete.push(brand.localFile);
      this.businessInfoModel.oldBrands.splice(i, 1);
      this.businessInfoModel.brands = [
        ...this.businessInfoModel.oldBrands,
        ...this.businessInfoModel.newBrands,
      ];
    }
    console.log(this.businessInfoModel);
  }

  addBrand() {
    if (this.businessInfoModel.newBrands.length === 0) {
      this.businessInfoModel.newBrands.push({ name: "", isNew: true });
      this.businessInfoModel.brands = [
        ...this.businessInfoModel.oldBrands,
        ...this.businessInfoModel.newBrands,
      ];
    } else {
      const incompleteBrands = this.businessInfoModel.newBrands.filter(
        (brand) => {
          if (!brand.name || !brand.name.trim()) {
            brand.hasError = true;
            return true;
          }
          brand.hasError = false;
        }
      );
      if (incompleteBrands.length > 0) {
        this.toastr.error("Please fill all the details of previous brands");
      } else {
        this.businessInfoModel.newBrands.push({ name: "", isNew: true });
        this.businessInfoModel.brands = [
          ...this.businessInfoModel.oldBrands,
          ...this.businessInfoModel.newBrands,
        ];
      }
    }
  }

  // checkForErrorInBrandData(brand) {
  //   if (
  //     !brand.name ||
  //     !brand.name.trim() 
  //   ) {
  //     brand.hasError = true;
  //     return true;
  //   } else {
  //     brand.hasError = false;
  //   }
  // }

  checkForErrorInBrandData(brand, index) {
    if (!brand.name || !brand.name.trim()) {
      brand.hasError = true;
    } else {
      brand.hasError = false;
    }
    const allBrands = this.businessInfoModel.brands.map((br, i)=>{
      if(index !== i)
      return br.name.toLowerCase()
    });
    if(allBrands.indexOf(brand.name.toLowerCase()) >= 0){
      brand.isDuplicate = true;
      brand.hasError = true;
    }else{
      brand.isDuplicate = false;
      brand.hasError = false;
    }
  }

  //Update and edit brand data end

  //Create, Update and Edit distributor details start

  checkForDistributorNameError(distributor) {
    if (!distributor.name || !distributor.name.trim()) {
      distributor.hasNameError = true;
    } else {
      distributor.hasNameError = false;
    }
  }

  checkForDistributorEmailError(distributor) {
    if (
      !distributor.email ||
      !distributor.email.trim() ||
      !this.emailRegEx.test(distributor.email)
    ) {
      distributor.hasEmailError = true;
    } else {
      distributor.hasEmailError = false;
    }
  }

  checkForDistributorLocationError(distributor) {
    if (!distributor.location || !distributor.location.trim()) {
      distributor.hasLocationError = true;
    } else {
      distributor.hasLocationError = false;
    }
  }

  addDistributor() {
    if (this.businessInfoModel.distributors.length === 0) {
      this.businessInfoModel.distributors.push({
        name: "",
        email: "",
        location: "",
      });
    } else {
      const inCompleteDistributors = this.businessInfoModel.distributors.filter(
        (distributor) => {
          if (
            !distributor.name ||
            !distributor.name.trim() ||
            !distributor.email ||
            !distributor.email.trim() ||
            !this.emailRegEx.test(distributor.email) ||
            !distributor.location ||
            !distributor.location.trim()
          ) {
            this.checkForDistributorNameError(distributor);
            this.checkForDistributorEmailError(distributor);
            this.checkForDistributorLocationError(distributor);
            return true;
          }
        }
      );
      if (inCompleteDistributors.length > 0) {
        this.toastr.error("Please fill all the previous distributor details");
      } else {
        this.businessInfoModel.distributors.push({
          name: "",
          email: "",
          location: "",
        });
      }
    }
  }

  removeDistributor(i) {
    this.businessInfoModel.distributors.splice(i, 1);
  }

  clearPreviousDistributors() {
    this.businessInfoModel.distributors = [
      { name: "", email: "", location: "" },
    ];
  }

  //Create, Update and Edit distributor details end

  //Create, Update and Edit egg processing units details start

  checkForEggProcessingUnitsNameError(unit) {
    if (!unit.name || !unit.name.trim()) {
      unit.hasNameError = true;
    } else {
      unit.hasNameError = false;
    }
  }

  checkForEggProcessingUnitsEmailError(unit) {
    if (
      !unit.email ||
      !unit.email.trim() ||
      !this.emailRegEx.test(unit.email)
    ) {
      unit.hasEmailError = true;
    } else {
      unit.hasEmailError = false;
    }
  }

  checkForEggProcessingUnitsLocationError(unit) {
    if (!unit.location || !unit.location.trim()) {
      unit.hasLocationError = true;
    } else {
      unit.hasLocationError = false;
    }
  }

  addEggProcessingUnit() {
    if (this.businessInfoModel.eggProcessingUnits.length === 0) {
      this.businessInfoModel.eggProcessingUnits.push({
        name: "",
        email: "",
        location: "",
      });
    } else {
      const incompleteEggProcessingUnits = this.businessInfoModel.eggProcessingUnits.filter(
        (unit) => {
          if (
            !unit.name ||
            !unit.name.trim() ||
            !unit.email ||
            !unit.email.trim() ||
            !this.emailRegEx.test(unit.email) ||
            !unit.location ||
            !unit.location.trim()
          ) {
            this.checkForEggProcessingUnitsNameError(unit);
            this.checkForEggProcessingUnitsEmailError(unit);
            this.checkForEggProcessingUnitsLocationError(unit);
            return true;
          }
        }
      );
      if (incompleteEggProcessingUnits.length > 0) {
        this.toastr.error(
          "Please fill all the previous egg processing units details"
        );
      } else {
        this.businessInfoModel.eggProcessingUnits.push({
          name: "",
          email: "",
          location: "",
        });
      }
    }
  }

  removeEggProcessingUnit(i) {
    this.businessInfoModel.eggProcessingUnits.splice(i, 1);
  }

  clearPreviousEggProcessingUnits() {
    this.businessInfoModel.eggProcessingUnits = [
      { name: "", email: "", location: "" },
    ];
  }

  //Create, Update and Edit egg processing units details end

  businessInfoModel: any = {
    productionCapacities: [],
    businessInfo: {
      through_distributors: false,
      to_retailers: false,
      is_having_brands: false,
      to_egg_processing_units: false,
      is_cage_free_certified: false,
      other_production_systemName: "",
      productionSystems: [],
    },
    distributors: [{ name: "", email: "", location: "" }],
    eggProcessingUnits: [{ name: "", email: "", location: "" }],
    oldCertifications: [],
    newCertifications: [{ name: "", isNew: true }],
    oldBrands: [],
    newBrands: [{ name: "", isNew: true }],
    certifications: [],
    brands: [],
    newCertificationFiles: [],
    newBrandFiles: [],
    filesToDelete: [],
  };

  onProductionSystemsValueChange() {
    if (this.businessInfoModel.businessInfo.productionSystems.indexOf(6) < 0) {
      this.businessInfoModel.businessInfo.other_production_systemName = "";
    }
  }

  checkForCapacityError(capacity) {
    if (!capacity.capacity || !capacity.capacity.trim()) {
      capacity.hasCapacityError = true;
    } else {
      capacity.hasCapacityError = false;
    }
  }

  checkForUnitsError(capacity) {
    if (!capacity.units || !capacity.units.trim()) {
      capacity.hasUnitsError = true;
    } else {
      capacity.hasUnitsError = false;
    }
  }

  clearPreviousCertificationsData() {
    if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
      this.businessInfoModel.newCertifications = [{ name: "", isNew: true }];
      this.businessInfoModel.certifications = [
        ...this.businessInfoModel.newCertifications,
      ];
    } else {
      this.businessInfoModel.certifications = [];
      this.businessInfoModel.newCertifications = [];
    }
  }
  clearPreviousBrandsData() {
    if (this.businessInfoModel.businessInfo.is_having_brands) {
      this.businessInfoModel.newBrands = [{ name: "", isNew: true }];
      this.businessInfoModel.brands = [...this.businessInfoModel.newBrands];
    } else {
      this.businessInfoModel.brands = [];
      this.businessInfoModel.newBrands = [];
    }
  }
  clearPreviousDistributorsData() {
    this.businessInfoModel.distributors = [
      { name: "", email: "", location: "" },
    ];
  }
  clearPreviousEggProcessingUnitsData() {
    this.businessInfoModel.eggProcessingUnits = [
      { name: "", email: "", location: "" },
    ];
  }
  createProductionCapacitiesArray(data) {
    this.businessInfoModel.productionCapacities = [];
    if (data.businessListIds.length > 0) {
      if (data.businessListIds.indexOf(1) >= 0) {
        const business = data.sellerBusinessList.filter((business) => {
          return business.seller_business_list_id === 1;
        })[0];
        this.businessInfoModel.productionCapacities.push({
          name: business.businessName,
          businessId: 1,
          capacity: "",
          units: "",
          typeId: 0,
          isUnitSelected: false,
          isCapacityEntered: false,
        });
      }
      if (data.businessListIds.indexOf(2) >= 0) {
        const business = data.sellerBusinessList.filter((business) => {
          return business.seller_business_list_id === 2;
        })[0];
        data.sellerBusinessListSubTypes.map((type) => {
          this.businessInfoModel.productionCapacities.push({
            name:
              type.seller_business_list_sub_type_id === 4
                ? `${business.businessName} (${type.businessSubTypeName}-${type.other_name})`
                : `${business.businessName} (${type.businessSubTypeName})`,
            businessId: 2,
            capacity: "",
            units: "",
            typeId: type.seller_business_list_sub_type_id,
            isUnitSelected: false,
            isCapacityEntered: false,
          });
        });
      }
    }
  }

  validateBusinessInfoFormSubmit() {}

  cancelCompanyInfoEdit() {
    this.isCompanyInfoFormEditable = false;
    this.getCompanyInfo();
  }
  enableCompanyInfoEdit() {
    this.isCompanyInfoFormEditable = true;
  }

  natureOfBusinessList: any = [];
  getNatureOfBusinessList() {
    this.spinner.show();
    this.masterDataService.getSellerNatureOfBusinessList().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.natureOfBusinessList = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  selectedBusinessListSubTypes: any = [];
  onBusinessListValueChange() {
    if (this.selectedBusinessListSubTypes.indexOf(4) < 0) {
      this.companyInfoModel.nature_of_business_list_otherName = null;
    }
    if (this.selectedBusinessList.indexOf(2) < 0) {
      this.selectedBusinessListSubTypes = [];
    }
  }

  onBusinessListSubTypeValueChange() {
    if (this.selectedBusinessListSubTypes.indexOf(4) < 0) {
      this.companyInfoModel.nature_of_business_list_sub_type_otherName = null;
    }
  }

  natureOfBusinessListSubTypes: any = [];
  getNatureOfBusinessListSubTypes() {
    this.spinner.show();
    this.masterDataService.getSellerNatureOfBusinessListSubTypes().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.natureOfBusinessListSubTypes = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  cfpSystems: any = [];
  getCFPSystems() {
    this.spinner.show();
    this.masterDataService.getCFPSystems().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.cfpSystems = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  Number_Dot_Only(e){
    var keyCode = (e.keyCode ? e.keyCode : e.which);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 46 ){
      return true
  }
  return false;
  }
  isCompanyEditSubmitBtnDisabled = false;
  editCompanyInfo() {
    this.isCompanyEditSubmitBtnDisabled = true;
    const dataTosend = _.pick(
      this.companyInfoModel,
      this.companyInfoUpdateKeys
    );
    dataTosend.nature_of_business_list = this.selectedBusinessList;
    dataTosend.nature_of_business_list_sub_types = this.selectedBusinessListSubTypes;
    this.sellerService
      .updateSellerCompanyInfo(dataTosend, this.loggedInUser.id)
      .subscribe(
        (resp: any) => {
          this.isCompanyEditSubmitBtnDisabled = false;
          this.toastr.success(resp.message);
          this.isCompanyInfoFormEditable = false;
          this.getCompanyInfo();
          this.userProfileService.userDataChanged.next();
        },
        (err: HttpErrorResponse) => {
          this.isCompanyEditSubmitBtnDisabled = false;
          this.toastr.error(err.error.message);
        }
      );
  }
  companyInfoUpdateKeys = [
    "company_name",
    "phone",
    "website",
    "contact_person_first_name",
    "contact_person_last_name",
    "contact_person_name_title",
    "country",
    "city",
    "nature_of_business_list",
    "nature_of_business_list_otherName",
    "nature_of_business_list_sub_types",
    "nature_of_business_list_sub_type_otherName",
    "contact_person_job_title",
    "address_line_1",
    "address_line2",
    "pin_code",
    "description",
    "status",
  ];

  countries: any = [];
  getCountries() {
    this.spinner.show();
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.countries = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  isCountrySelected = false;
  isCitySelected = false;
  validateSelectedCountry() {
    if (this.companyInfoModel.country) {
      this.isCountrySelected = true;
    } else {
      this.isCountrySelected = false;
    }
  }

  countriesListFormatter(data) {
    return data.country;
  }

  stateListFormatter(data) {
    return data.state;
  }

  countrySelected(data) {
    this.companyInfoModel.country = data.country_code;
    this.getCities();
    this.getCountryPhoneCode();
  }

  activeIndex = 0;
  storeSelected(i) {
    this.activeIndex = i;
  }

  supplyLocationIdToDelete: any;
  storeSupplyLocationIdToDelete(locationId) {
    this.supplyLocationIdToDelete = locationId;
  }

  onDeleteSupplyLocationClose() {
    this.supplyLocationIdToDelete = "";
    this.supplyLocationDeleteModalCloseBtn.nativeElement.click();
  }

  isDeleteBtnDisabled = false;
  deleteSellerProductSupplyLocation() {
    this.isDeleteBtnDisabled = true;
    this.sellerService
      .deleteSellerSupplyLocations(this.supplyLocationIdToDelete)
      .subscribe(
        (resp: any) => {
          this.isDeleteBtnDisabled = false;
          this.toastr.success(resp.message);
          this.getSellerSupplyLocations();
          this.supplyLocationDeleteModalCloseBtn.nativeElement.click();
        },
        (err: HttpErrorResponse) => {
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
        }
      );
  }

  showSellerPreview() {
    this.router.navigate(["/seller/productPreview"], {
      queryParams: { sellerId: this.loggedInUser.id },
    });
  }

  country_phone_code: string;
  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.companyInfoModel.country
    );
  }

  clerPreviousSelectedCountry() {
    this.companyInfoModel.country = "";
    this.cities = [];
    this.selectedCity = "";
    this.companyInfoModel.city = "";
    this.companyInfoModel.state = "";
  }

  clerPreviousSelectedState() {
    this.cities = [];
    this.selectedCity = "";
    this.companyInfoModel.city = "";
  }

  validateSelectedCity() {
    if (this.companyInfoModel.city) {
      this.isCitySelected = true;
    } else {
      this.isCitySelected = false;
    }
  }

  citiesListFormatter(data) {
    return data.city;
  }

  citySelected(data) {
    this.companyInfoModel.city = data.id;
  }

  clerPreviousSelectedCity() {
    this.companyInfoModel.city = "";
  }

  cities: any = [];
  selectedCity: any;
  getCities() {
    this.spinner.show();
    this.masterDataService.getCities(this.companyInfoModel.country).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.cities = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  supplyLocations: any = [];
  getSellerSupplyLocations() {
    this.sellerService.getSellerSupplyLocations().subscribe((resp: any) => {
      this.supplyLocations = resp.data;
    });
  }

  supplyLocationsModel: any = {};
  isSupplyLocationsCountrySelected = false;
  isSupplyLocationsCitySelected = false;
  validateSelectedSupplyLocationsCountry() {
    if (this.supplyLocationsModel.country) {
      this.isSupplyLocationsCountrySelected = true;
    } else {
      this.isSupplyLocationsCountrySelected = false;
    }
  }

  supplyLocationsCountrySelected(data) {
    this.supplyLocationsModel.country = data.country_code;
    this.getSupplyLocationsCities();
  }

  supplyLocationsSelectedCity: any;
  supplyLocationsSelectedCountry: any;
  clerPreviousSelectedSupplyLocationsCountry() {
    this.supplyLocationsModel.country = "";
    this.supplyLocationsCities = [];
    this.supplyLocationsSelectedCity = "";
    this.supplyLocationsModel.city = "";
  }

  validateSelectedSupplyLocationsCity() {
    if (this.supplyLocationsModel.city) {
      this.isSupplyLocationsCitySelected = true;
    } else {
      this.isSupplyLocationsCitySelected = false;
    }
  }

  supplyLocationsCitySelected(data) {
    this.supplyLocationsModel.city = data.id;
  }

  clerPreviousSelectedSupplyLocationsCity() {
    this.supplyLocationsModel.city = "";
  }

  supplyLocationsCities: any = [];
  selectedSupplyLocationsCity: any;
  getSupplyLocationsCities() {
    this.spinner.show();
    this.masterDataService
      .getCities(this.supplyLocationsModel.country)
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          this.supplyLocationsCities = data.data;
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
  }

  @ViewChild("slf") supplyLocationForm: NgForm;
  isAddSupplyLocationsButtonDisabled = false;
  isSupplyLocationEdit = false;
  createSellerSupplyLocation() {
    this.spinner.show();
    this.isAddSupplyLocationsButtonDisabled = true;
    let dataToSend = {
      ...this.supplyLocationsModel,
    };
    this.sellerService.addSellerSupplyLocations(dataToSend).subscribe(
      (resp: any) => {
        this.spinner.hide();
        if (this.supplyLocations.length > 0) {
          this.createSupplyLocationCloseBtn.nativeElement.click();
        } else {
          this.supplyLocationsModel = {};
        }
        this.getSellerSupplyLocations();
        dataToSend = {};
        this.isAddSupplyLocationsButtonDisabled = false;
        this.supplyLocationsSelectedCity = "";
        this.supplyLocationsSelectedCountry = "";
        this.isSupplyLocationsCountrySelected = false;
        this.isSupplyLocationsCitySelected = false;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.isAddSupplyLocationsButtonDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  clerSupplyLocationsModel() {
    this.isSupplyLocationEdit = false;
    this.supplyLocationsModel = {};
    this.supplyLocationForm.resetForm();
  }

  enableSupplyLocationEdit(location) {
    this.isSupplyLocationEdit = true;
    this.supplyLocationsModel = { ...location };
    this.supplyLocationsSelectedCountry = {
      country_code: location.country,
      country: location.countryName,
    };
    this.supplyLocationsSelectedCity = {
      id: location.city,
      city: `${location.cityName} (${location.state})`,
    };
    this.getSupplyLocationsCities();
    this.isSupplyLocationsCitySelected = true;
    this.isSupplyLocationsCountrySelected = true;
  }

  onSupplyLocationFormSubmit() {
    if (this.isSupplyLocationEdit) {
      this.updateSellerSupplyLocation();
    } else {
      this.createSellerSupplyLocation();
    }
  }

  updateSellerSupplyLocation() {
    this.spinner.show();
    this.isAddSupplyLocationsButtonDisabled = true;
    let dataToSend = {
      ..._.pick(this.supplyLocationsModel, [
        "country",
        "city",
        "contact_name",
        "contact_email",
      ]),
    };
    let id = this.supplyLocationsModel.id;
    this.sellerService.updateSellerSupplyLocations(dataToSend, id).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.createSupplyLocationCloseBtn.nativeElement.click();
        this.getSellerSupplyLocations();
        dataToSend = {};
        id = "";
        this.isAddSupplyLocationsButtonDisabled = false;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.isAddSupplyLocationsButtonDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  formImageData: FormData;
  @ViewChild("fileUpload")
  selectedFileElement: ElementRef;
  uploadProfilePic(event: any) {
    if (event.target.files && event.target.files[0]) {
      const fileDetails = event.target.files[0];
      this.formImageData = new FormData();
      this.formImageData.append("files", fileDetails);
      this.spinner.show();
      this.userProfileService
        .uploadUserProfilePic(this.formImageData)
        .subscribe(
          (resp: any) => {
            this.spinner.hide();
            this.selectedFileElement.nativeElement.value = "";
            this.getCompanyInfo();
            this.formImageData = new FormData();
            this.userProfileService.userDataChanged.next();
          },
          (err) => {
            this.spinner.hide();
            this.selectedFileElement.nativeElement.value = "";
            this.formImageData = new FormData();
            this.toastr.error(err.error.message);
          }
        );
    }
  }

  orgImageData: FormData = new FormData();
  @ViewChild("orgImages")
  orgImages: ElementRef;
  uploadOrgImages(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const fileDetails = event.target.files[i];
        this.orgImageData.append("files[]", fileDetails);
      }
      this.orgImageData.append("oldImages", JSON.stringify(this.orgImagesList));
      this.orgImageData.append("imagesToDelete", JSON.stringify([]));
      this.spinner.show();
      this.userProfileService.uploadOrgImages(this.orgImageData).subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.orgImages.nativeElement.value = "";
          this.orgImageData = new FormData();
          this.getOrgImages();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
    }
  }

  deletOrgImage(index) {
    this.spinner.show();
    const oldImages = [...this.orgImagesList];
    const imageToDelete = oldImages.splice(index, 1);
    this.orgImageData.append("oldImages", JSON.stringify(oldImages));
    this.orgImageData.append("imagesToDelete", JSON.stringify(imageToDelete));
    this.spinner.show();
    this.userProfileService.uploadOrgImages(this.orgImageData).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.getOrgImages();
        this.orgImageData = new FormData();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }
  fourtImage: any;
  orgImagesList: any = [];
  getOrgImages() {
    this.spinner.show();
    this.userProfileService.getSellerOrgImages(this.loggedInUser.id).subscribe(
      (resp: any) => {
        this.spinner.hide();
        let orimages = resp.data;
        this.orimageLength = orimages.length;
        if (this.orimageLength > 3) {
          this.showExtraOrImages = true;
          this.fourtImage = orimages[3];
        }
        this.orgImagesList = orimages;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
      }
    );
  }

  isBusinessInfoEdit = false;
  isBusinessInfoAlreadyAdded = false;
  isBusinessInfoSubmitButtonDisabled = false;
  submitBusinessInfo() {
    this.isBusinessInfoSubmitButtonDisabled = true;
    this.spinner.show();
    if (!this.isBusinessInfoAlreadyAdded && !this.isBusinessInfoEdit) {
      if (!this.validateAddBusinessInfoInputData(this.businessInfoModel)) {
        this.isBusinessInfoSubmitButtonDisabled = false;
        this.spinner.hide();
        return;
      }
      const inputData = this.createAddBusinessInfoInputData();
      const dataToSend = new FormData();
      dataToSend.append(
        "productionCapacities",
        JSON.stringify(inputData.prodCapacities)
      );
      dataToSend.append(
        "businessInfo",
        JSON.stringify(this.businessInfoModel.businessInfo)
      );
      if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
        dataToSend.append(
          "newCertifications",
          JSON.stringify(inputData.certifications)
        );
      }
      if (this.businessInfoModel.businessInfo.is_having_brands) {
        dataToSend.append("newBrands", JSON.stringify(inputData.brands));
      }
      if (this.businessInfoModel.businessInfo.through_distributors) {
        dataToSend.append(
          "distributors",
          JSON.stringify(inputData.distributors)
        );
      }
      if (this.businessInfoModel.businessInfo.to_egg_processing_units) {
        dataToSend.append(
          "eggProcessingUnits",
          JSON.stringify(inputData.egpUnits)
        );
      }
      if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
        for (
          let i = 0;
          i < this.businessInfoModel.newCertificationFiles.length;
          i++
        ) {
          const fileDetails = this.businessInfoModel.newCertificationFiles[i];
          dataToSend.append("certifications[]", fileDetails);
        }
      }
      for (let i = 0; i < this.businessInfoModel.newBrandFiles.length; i++) {
        const fileDetails = this.businessInfoModel.newBrandFiles[i];
        debugger;
        if(fileDetails){
          dataToSend.append("brands[]", fileDetails);
        }else{
          dataToSend.append("brands[]", new File(["Hello"], "Hello"));
        }
      }
      this.sellerService.addSellerBusinessInfo(dataToSend).subscribe(
        (resp: any) => {
          this.isBusinessInfoSubmitButtonDisabled = false;
          this.spinner.hide();
          this.toastr.success(resp.message);
          this.getSellerBusinessInfo();
        },
        (err: HttpErrorResponse) => {
          this.isBusinessInfoSubmitButtonDisabled = false;
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
      console.log("The limit is crossed");
    }
    if (this.isBusinessInfoAlreadyAdded && this.isBusinessInfoEdit) {
      if (!this.validateAddBusinessInfoInputData(this.businessInfoModel)) {
        this.isBusinessInfoSubmitButtonDisabled = false;
        this.spinner.hide();
        return;
      }
      const inputData = this.createAddBusinessInfoInputData();
      const dataToSend = new FormData();
      dataToSend.append(
        "productionCapacities",
        JSON.stringify(inputData.prodCapacities)
      );
      dataToSend.append(
        "filesToDelete",
        JSON.stringify(this.businessInfoModel.filesToDelete)
      );
      dataToSend.append(
        "businessInfo",
        JSON.stringify(this.businessInfoModel.businessInfo)
      );
      dataToSend.append(
        "oldBrands",
        JSON.stringify(
          this.businessInfoModel.oldBrands.map((brand) => {
            return {
              name: brand.name,
              file: brand.localFile,
            };
          })
        )
      );
      if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
        dataToSend.append(
          "newCertifications",
          JSON.stringify(inputData.certifications)
        );
        dataToSend.append(
          "oldCertifications",
          JSON.stringify(
            this.businessInfoModel.oldCertifications.map((certification) => {
              return {
                name: certification.name,
                file: certification.localFile,
              };
            })
          )
        );
      }
      dataToSend.append("newBrands", JSON.stringify(inputData.brands));
      if (this.businessInfoModel.businessInfo.through_distributors) {
        dataToSend.append(
          "distributors",
          JSON.stringify(inputData.distributors)
        );
      }
      if (this.businessInfoModel.businessInfo.to_egg_processing_units) {
        dataToSend.append(
          "eggProcessingUnits",
          JSON.stringify(inputData.egpUnits)
        );
      }
      if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
        // if (this.businessInfoModel.newCertificationFiles.length > 0) {
        for (
          let i = 0;
          i < this.businessInfoModel.newCertificationFiles.length;
          i++
        ) {
          const fileDetails = this.businessInfoModel.newCertificationFiles[i];
          dataToSend.append("certifications[]", fileDetails);
        }
        // } else {
        //   dataToSend.append("certifications[]", "");
        // }
      }
      // if (this.businessInfoModel.newBrandFiles.length > 0) {
      for (let i = 0; i < this.businessInfoModel.newBrandFiles.length; i++) {
        const fileDetails = this.businessInfoModel.newBrandFiles[i];
        if(fileDetails){
          dataToSend.append("brands[]", fileDetails);
        }else{
          dataToSend.append("brands[]", new File(["Hello"], "Hello"));
        }
      }
      // }else{
      //   dataToSend.append("brands[]", "");
      // }
      this.sellerService
        .updateSellerBusinessInfo(dataToSend, this.loggedInUser.id)
        .subscribe(
          (resp: any) => {
            this.isBusinessInfoSubmitButtonDisabled = false;
            this.spinner.hide();
            this.toastr.success(resp.message);
            this.isBusinessInfoEdit = false;
            this.getSellerBusinessInfo();
          },
          (err: HttpErrorResponse) => {
            this.isBusinessInfoSubmitButtonDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
          }
        );
      console.log(this.businessInfoModel);
    }
  }

  enableBusinessInfoEdit() {
    this.isBusinessInfoEdit = true;
  }

  cancelBusinessInfoEdit() {
    this.isBusinessInfoEdit = false;
    this.getSellerBusinessInfo();
  }

  validateAddBusinessInfoInputData(inputData) {
    if (
      !this.validateProductionCapacitiesInputData(
        inputData.productionCapacities
      )
    ) {
      return false;
    }
    if (
      !this.validateProductionSystemsInputData(
        this.businessInfoModel.businessInfo.productionSystems
      )
    ) {
      return false;
    }
    if (
      !this.validateProductionDistributionSystem(
        this.businessInfoModel.businessInfo
      )
    ) {
      return false;
    }
    if (this.businessInfoModel.businessInfo.through_distributors) {
      if (
        !this.validateProductDistributorsInputData(
          this.businessInfoModel.distributors
        )
      ) {
        return false;
      }
    }
    if (this.businessInfoModel.businessInfo.to_egg_processing_units) {
      if (
        !this.validateProductDistributorsInputData(
          this.businessInfoModel.eggProcessingUnits
        )
      ) {
        return false;
      }
    }
    if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
      let certificationsToSend;
      if (this.isBusinessInfoAlreadyAdded) {
        certificationsToSend = this.businessInfoModel.certifications;
      } else {
        certificationsToSend = this.businessInfoModel.newCertifications;
      }
      if (!this.validateCertificationsInputData(certificationsToSend)) {
        return false;
      }
    }
    if (this.businessInfoModel.businessInfo.is_having_brands) {
      let brandsToSend;
      if (this.isBusinessInfoAlreadyAdded) {
        brandsToSend = this.businessInfoModel.brands;
      } else {
        brandsToSend = this.businessInfoModel.newBrands;
      }
      if (!this.validateBrandsInputData(brandsToSend)) {
        return false;
      }
    }
    return true;
  }

  validateProductionCapacitiesInputData(capacities) {
    const invalidCapacities = capacities.filter((capacity: any) => {
      if (!capacity.capacity || !capacity.capacity.trim()) {
        capacity.hasCapacityError = true;
      }
      if (!capacity.units || !capacity.units.trim()) {
        capacity.hasUnitsError = true;
      }
      if (capacity.hasUnitsError || capacity.hasCapacityError) {
        return true;
      }
    });
    if (invalidCapacities.length > 0) {
      this.toastr.error("Please fill all the production capacity details");
      return false;
    }
    return true;
  }

  validateProductionSystemsInputData(systems) {
    if (systems.length === 0) {
      this.toastr.error("Please select atleast one production system details");
      return false;
    }
    if (
      systems.indexOf(6) >= 0 &&
      (!this.businessInfoModel.businessInfo.other_production_systemName ||
        !this.businessInfoModel.businessInfo.other_production_systemName.trim())
    ) {
      this.toastr.error("Please specify other production system");
      return false;
    }
    return true;
  }

  validateProductionDistributionSystem(businessInfo) {
    if (
      !businessInfo.through_distributors &&
      !businessInfo.to_retailers &&
      !businessInfo.to_egg_processing_units
    ) {
      this.toastr.error(
        "Please select atleast one of the distribution systems"
      );
      return false;
    }
    return true;
  }

  validateProductDistributorsInputData(distributors) {
    if (distributors.length === 0) {
      this.toastr.error("Atleast one distributor is required");
      return false;
    }
    const invalidDistributors = distributors.filter((distributor: any) => {
      this.checkForDistributorNameError(distributor);
      this.checkForDistributorEmailError(distributor);
      this.checkForDistributorLocationError(distributor);
      if (
        distributor.hasNameError ||
        distributor.hasEmailError ||
        distributor.hasLocationError
      ) {
        return true;
      }
    });
    if (invalidDistributors.length > 0) {
      this.toastr.error("Please fill all the distributors details");
      return false;
    }
    return true;
  }

  validateProductEGPUnitsInputData(units) {
    const invalidUnits = units.filter((unit: any) => {
      this.checkForEggProcessingUnitsNameError(unit);
      this.checkForEggProcessingUnitsEmailError(unit);
      this.checkForEggProcessingUnitsLocationError(unit);
      if (unit.hasNameError || unit.hasEmailError || unit.hasLocationError) {
        return true;
      }
    });
    if (invalidUnits.length > 0) {
      this.toastr.error("Please fill all the egg processing units details");
      return false;
    }
    return true;
  }

  validateCertificationsInputData(certifications) {
    if (certifications.length === 0) {
      this.toastr.error("Atleast one certification is required");
      return false;
    }
    const incompleteCertifications = certifications.filter((certification) => {
      if (
        !certification.name ||
        !certification.name.trim() || certification.hasError|| 
        (!certification.isFileUploaded && certification.isNew)
      ) {
        certification.hasError = true;
        return true;
      }
      certification.hasError = false;
    });
    if (incompleteCertifications.length > 0) {
      this.toastr.error("Please check errors of certifications");
      return false;
    }
    return true;
  }

  validateBrandsInputData(brands) {
    if (brands.length === 0) {
      this.toastr.error("Atleast one brand is required");
      return false;
    }
    const incompleteBrands = brands.filter((brand) => {
      if (
        !brand.name ||
        !brand.name.trim() || brand.hasError
      ) {
        brand.hasError = true;
        return true;
      }
      brand.hasError = false;
    });
    if (incompleteBrands.length > 0) {
      this.toastr.error("Please check errors of brands");
      return false;
    }
    return true;
  }

  createAddBusinessInfoInputData() {
    const inputData: any = {};
    inputData.prodCapacities = this.createProdCapacityInputData();
    if (this.businessInfoModel.businessInfo.through_distributors) {
      inputData.distributors = this.createDistributorsInputData();
    }
    if (this.businessInfoModel.businessInfo.to_egg_processing_units) {
      inputData.egpUnits = this.createEGPUnitsInputData();
    }
    if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
      inputData.certifications = this.createCertificationsInputData();
    }
    inputData.brands = this.createBrandsInputData();
    return inputData;
  }
  createProdCapacityInputData() {
    const prodCapacities = this.businessInfoModel.productionCapacities.map(
      (capacity) => {
        return {
          capacity: capacity.capacity,
          units: capacity.units,
          businessId: capacity.businessId,
          typeId: capacity.typeId,
        };
      }
    );
    return prodCapacities;
  }

  createDistributorsInputData() {
    const distributors = this.businessInfoModel.distributors.map(
      (distributor) => {
        return {
          name: distributor.name,
          email: distributor.email,
          location: distributor.location,
        };
      }
    );
    return distributors;
  }

  createEGPUnitsInputData() {
    const units = this.businessInfoModel.eggProcessingUnits.map((unit) => {
      return {
        name: unit.name,
        email: unit.email,
        location: unit.location,
      };
    });
    return units;
  }

  createCertificationsInputData() {
    const certifications = this.businessInfoModel.newCertifications.map(
      (certification) => {
        return certification.name;
      }
    );
    return certifications;
  }

  createBrandsInputData() {
    const brands = this.businessInfoModel.newBrands.map((brand) => {
      return { name: brand.name, isFileUploaded: brand.isFileUploaded };
    });
    return brands;
  }

  numberandDotOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode < 46)) {
      return false;
    }
    return true;
  }

  isAdminApprovalSubmitBtnDisabled = false;
  submitSellerForAdminApproval() {
    this.isAdminApprovalSubmitBtnDisabled = true;
    this.spinner.show();
    this.sellerService.submitSellerForAdminApproval().subscribe(
      (resp: any) => {
        this.sellerService.onSellerStatusChange.next("Status changed");
        this.getSellerStatusInfo();
        this.spinner.hide();
        this.isAdminApprovalSubmitBtnDisabled = false;
        this.getCompanyInfo();
        this.toastr.success(resp.message);
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.isAdminApprovalSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  deleteOrgImage(location) {
    this.spinner.show();
    const dataToSend = {
      location,
    };
    this.sellerService.deleteSellerOrgImage(dataToSend).subscribe(
      (resp: any) => {
        this.toastr.success(resp.message);
        this.getOrgImages();
      },
      (err: HttpErrorResponse) => {}
    );
  }

  sellerStatusCode: string;
  getSellerStatusInfo() {
    this.sellerService.getCompanyStatusInfo().subscribe((resp: any) => {
      this.sellerStatusCode = resp.data.statusCode;
    });
  }
}
