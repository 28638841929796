import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }


  public userProfileImage : BehaviorSubject<any> = new BehaviorSubject({
    profileUrl:'../../../assets/images/user.jpg',
    userName:'User Name'
  });

  public userDataChanged: Subject<any> = new Subject();

  getUserDetails(id){
    return this.httpMasterService.getService(`auth/users/${id}`)
  }

  updateUserDetails(id, endPoint, data){
    return this.httpMasterService.patchService(`${endPoint}/profile/${id}`, data)
  }

  updateBuyerDetails(id, data){
    return this.httpMasterService.patchService(`buyers/profile/${id}`, data)
  }

  uploadUserProfilePic(data){
    return this.httpMasterService.postService(`auth/uploadProfilePic`, data)
  }

  uploadOrgImages(data){
    return this.httpMasterService.postService(`sellers/uploadOrgImages`, data)
  }

  getSellerOrgImages(id){
    return this.httpMasterService.getService(`sellers/sellerOrgImages/${id}`)
  }

  changePasswordService(data){
    return this.httpMasterService.postService(`auth/changePassword`, data)
  }
}
