import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { SellersService } from './../../services/sellers.service';
import { Router } from '@angular/router';
import { CookieService } from 'angular2-cookie/services/cookies.service';

@Component({
  selector: 'app-seller-products-approvals',
  templateUrl: './seller-products-approvals.component.html',
  styleUrls: ['./seller-products-approvals.component.css']
})
export class SellerProductsApprovalsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild("pauseModelCloseBtn") pauseModelCloseBtn: ElementRef;
  @ViewChild("resumeModelCloseBtn") resumeModelCloseBtn: ElementRef;
  @ViewChild("deleteModelCloseBtn") deleteModelCloseBtn: ElementRef;

  constructor(
    private toastr: ToastrService,
    private adminService: AdminService,
    private sellerService: SellersService,
    private router: Router,
    private cookiesService: CookieService
  ) {}

  ngOnInit() {
    this.dtOptions = this.getDatatableOptions();
    this.getApprovedSellers();
    this.cookiesService.remove("adminViewedFrom");
  }

  sellersList: any = [];
  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blfrtip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub approved Sellers'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub approved Sellers'
        }
      ]
    };
  }

  getApprovedSellers(){
    this.adminService.getApprovedSellers().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.sellersList = resp.data;
    })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  showSellerPreview(userId){
    this.cookiesService.put("adminViewedFrom", "/admin/approved/sellers");
    this.router.navigate(['/admin/sellerDetails'], { queryParams: { sellerId: userId } })
  }

  currentSellerId: any;
  storeCurrentSellerId(id) {
    this.currentSellerId = id;
  }

  isSubmitBtnDisabled: boolean = false;
  removeCurrentSellerId() {
    this.currentSellerId = "";
    this.pauseModelCloseBtn.nativeElement.click();
    this.resumeModelCloseBtn.nativeElement.click();
    this.deleteModelCloseBtn.nativeElement.click();
  }

  pauseSeller(){
    this.isSubmitBtnDisabled = true;
    const data = {
      id: this.currentSellerId,
      status: "INACTIVE"
    };
    this.adminService.pauseSeller(data).subscribe(
      (resp: any) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.success("Seller services paused successfully.");
        this.removeCurrentSellerId();
        this.getApprovedSellers();
      },
      (err) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  resumeSeller(){
    this.isSubmitBtnDisabled = true;
    const data = {
      id: this.currentSellerId,
      status: "ACTIVE"
    };
    this.adminService.pauseSeller(data).subscribe(
      (resp: any) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.success("Seller services resumed successfully.");
        this.removeCurrentSellerId();
        this.getApprovedSellers();
      },
      (err) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  deleteSeller(){
    this.isSubmitBtnDisabled = true;
    const data = {
      id: this.currentSellerId
    };
    this.adminService.deleteSeller(data).subscribe(
      (resp: any) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.success("Seller deleted successfully.");
        this.removeCurrentSellerId();
        this.getApprovedSellers();
      },
      (err) => {
        this.isSubmitBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

}
