import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AdminService } from './../../services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.css']
})
export class SellerDetailsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private spinner: NgxSpinnerService
  ) { }

  productId: number;
  sellerId: number;
  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.productId = +queryParams.get("productId");
      this.sellerId = +queryParams.get("sellerId");
      if (!this.productId || !this.sellerId) {
        this.router.navigate(["/error"]);
      }
      this.getSellerProductDetails();
    });
   this.idBasedScrool();
  }

  sellerProductDetails: any = {};
  getSellerProductDetails(){
    this.spinner.show();
    // this.adminService.getSellerProductDetails({sellerId: this.sellerId, productId: this.productId}).subscribe((resp: any)=>{
    //   this.spinner.hide();
    //   this.sellerProductDetails = resp.data;
    // }, (err: HttpErrorResponse)=>{
    //   this.spinner.hide();
    //   if (err.status === 401) {
    //     this.router.navigate["/login"];
    //   } else if (err.status === 500) {
    //     this.router.navigate["/error"];
    //   }
    // })
  }

  idBasedScrool(){
    $('.scrollTo').click(function(){
      $('html, body').animate({
          scrollTop: $( $(this).attr('href') ).offset().top
      }, 500);
      return false;
  });
  }
}
