import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tearms-and-conditions',
  templateUrl: './tearms-and-conditions.component.html',
  styleUrls: ['./tearms-and-conditions.component.css']
})
export class TearmsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
