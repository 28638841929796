import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AdminService } from './../../services/admin.service';
import { PendingsService } from './../../services/pendings.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-pending-seller-products',
  templateUrl: './pending-seller-products.component.html',
  styleUrls: ['./pending-seller-products.component.css']
})
export class PendingSellerProductsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild("approveCloseBtn") approveCloseBtn: ElementRef;
  @ViewChild("rejectCloseBtn") rejectCloseBtn: ElementRef;
  @ViewChild("modifyCloseBtn") modifyCloseBtn: ElementRef;

  constructor(
    private cookiesService: CookieService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    private router: Router,
    private adminService: AdminService,
    private pendingsService: PendingsService
  ) { }

  loggedinUser: any = {};
  ngOnInit() {
    this.loggedinUser = this.cookiesService.getObject("user");
    this.dtOptions = this.getDatatableOptions();
    this.getSellersForAdminApproval();
    this.cookiesService.remove("adminViewedFrom");
    this.pendingsService.onBuyerSellerStatusChange.next();
  }

  sellersList: any = [];
  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blftip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub pending Sellers'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub pending Sellers'
        }
      ]
    };
  }

  getSellersForAdminApproval(){
    this.adminService.getSellersForAdminApproval().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.sellersList = resp.data;
    })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  showSellerPreview(userId){
    this.cookiesService.put("adminViewedFrom", "/admin/pending/sellers");
    this.router.navigate(['/admin/sellerDetails'], { queryParams: { sellerId: userId } })
  }

  // navigateToSellerProductDetails(data){
  //   this.router.navigate(['admin/productDetails'], { queryParams: { productId: data.id, sellerId: data.seller_id } })
  // }

  currentSellerId: any;
  storeCurrentSellerId(id) {
    this.currentSellerId = id;
  }

  removeCurrentSellerId() {
    this.currentSellerId = "";
    this.reasonOfReject = "";
    this.sellerModifications = "";
    this.approveCloseBtn.nativeElement.click();
    this.rejectCloseBtn.nativeElement.click();
  }

  isApprovalBtnDisabled = false;
  approveSeller() {
    this.isApprovalBtnDisabled = true;
    const data = {
      id: this.currentSellerId,
    };
    this.pendingsService.approveSeller(data).subscribe(
      (resp: any) => {
        this.isApprovalBtnDisabled = false;
        this.toastr.success("Seller approved successfully.");
        this.approveCloseBtn.nativeElement.click();
        this.getSellersForAdminApproval();
        this.pendingsService.onBuyerSellerStatusChange.next();
      },
      (err) => {
        this.isApprovalBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  isRejectBtnDisabled = false;
  reasonOfReject: string;
  rejectSeller() {
    if(!this.reasonOfReject || !this.reasonOfReject.trim()){
      this.toastr.error("Reason for rejecting is required");
      return;
    }
    this.isRejectBtnDisabled = true;
    const data = {
      id: this.currentSellerId,
      reason: this.reasonOfReject
    };
    this.pendingsService.rejectSeller(data).subscribe(
      (resp: any) => {
        this.isRejectBtnDisabled = false;
        this.toastr.success("Seller rejected successfully.");
        this.approveCloseBtn.nativeElement.click();
        this.getSellersForAdminApproval();
        this.pendingsService.onBuyerSellerStatusChange.next();
      },
      (err) => {
        this.isRejectBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  isModifyBtnDisabled = false;
  sellerModifications: string;
  sellerSubmitForModification() {
    if(!this.sellerModifications || !this.sellerModifications.trim()){
      this.toastr.error("Reason for review is required");
      return;
    }
    this.isModifyBtnDisabled = true;
    const data = {
      id: this.currentSellerId,
      reason: this.sellerModifications
    };
    this.pendingsService.sellerSubmitForModification(data).subscribe(
      (resp: any) => {
        this.isModifyBtnDisabled = false;
        this.toastr.success("Seller submitted for modification successfully.");
        this.modifyCloseBtn.nativeElement.click();
        this.getSellersForAdminApproval();
        this.pendingsService.onBuyerSellerStatusChange.next();
      },
      (err) => {
        this.isModifyBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

}