import { Component, OnInit } from "@angular/core";
import { SellersService } from "./../../services/sellers.service";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { CountryPhoneCodesService } from "./../../services/country-phone-codes.service";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { UserProfileService } from "./../../services/user-profile.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MasterDataService } from "./../../services/master-data.service";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: "app-seller-details-preview",
  templateUrl: "./seller-details-preview.component.html",
  styleUrls: ["./seller-details-preview.component.css"],
})
export class SellerDetailsPreviewComponent implements OnInit {
  orimageLength: any;
  showExtraOrImages = false;

  constructor(
    private sellerService: SellersService,
    private toastr: ToastrService,
    private countryCodesService: CountryPhoneCodesService,
    private cookiesService: CookieService,
    private userProfileService: UserProfileService,
    private spinner: NgxSpinnerService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  loggedInUser: any = {};
  sellerId: number;
  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      if(!params.sellerId || isNaN(+params.sellerId)){
        this.router.navigate(['/seller/productInfo'])
      }
      this.sellerId = +params.sellerId;
    })
    this.loggedInUser = this.cookiesService.getObject("user");
    console.log(this.loggedInUser)
    this.getCFPSystems();
    this.getOrgImages();
    this.getSellerSupplyLocations();
  }

  firstVisible = 3;

  companyInfoModel: any = {};
  businessInfoModel: any = {};
  getCompanyInfo() {
    this.sellerService.getCompanyInfoBySellerId(this.sellerId).subscribe(
      (resp: any) => {
        this.companyInfoModel = resp.data;
        this.createProductionCapacitiesArray({
          businessListIds: [...resp.data.businessListIds],
          businessListSubTypeIds: resp.data.businessListSubTypeIds
            ? [...resp.data.businessListSubTypeIds]
            : [],
          sellerBusinessList: [...resp.data.sellerBusinessList],
          sellerBusinessListSubTypes: resp.data.sellerBusinessListSubTypes
            ? [...resp.data.sellerBusinessListSubTypes]
            : [],
        });
        this.getSellerBusinessInfo();
        this.getCountryPhoneCode();
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.message);
      }
    );
  }

  backToTable(){
    const route = this.cookiesService.get("adminViewedFrom");
    this.router.navigate([route])
  }

  getSellerBusinessInfo() {
    this.sellerService.getSellerBusinessInfoBySellerId(this.sellerId).subscribe((resp: any) => {
      const prodCapacities = [...this.businessInfoModel.productionCapacities];
      resp.data.businessInfo.productionCapacities.map((capacity) => {
        prodCapacities.map((inner) => {
          if (
            inner.businessId === capacity.businessId &&
            inner.typeId === capacity.typeId
          ) {
            capacity.name = inner.name;
          }
        });
      });
      this.businessInfoModel = resp.data.businessInfo;
      this.businessInfoModel.brands = [...this.businessInfoModel.oldBrands];
      if (this.businessInfoModel.businessInfo.is_cage_free_certified) {
        this.businessInfoModel.certifications = [
          ...this.businessInfoModel.oldCertifications,
        ];
      } else {
        this.businessInfoModel.certifications = [
          ...this.businessInfoModel.newCertifications,
        ];
      }
      this.businessInfoModel.prodSysNames = this.businessInfoModel.businessInfo.productionSystems.map(
        (sys) => {
          if (sys == 6) {
            return this.cfpSystems.filter((cfp) => cfp.value == sys)[0].label + `(${this.businessInfoModel.businessInfo.other_production_systemName})`;
          } else {
            return this.cfpSystems.filter((cfp) => cfp.value == sys)[0].label;
          }
        }
      );
      console.log(this.businessInfoModel.prodSysNames)
    });
  }

  backToFindSeller(){
    let selectedBusinessList: any = this.cookiesService.getObject('selectedBusinessList');
    let selectedCountry = this.cookiesService.get('selectedCountry');
    let selectedCity = this.cookiesService.get('selectedCity');
    const queryParams: any = {};
    if(selectedBusinessList && selectedBusinessList.length > 0){
      queryParams.selectedBusinessList = JSON.stringify(selectedBusinessList);
    }
    if(selectedCountry){
      queryParams.selectedCountry = selectedCountry;
    }
    if(selectedCity){
      queryParams.selectedCity = selectedCity;
    }
    this.router.navigate(['/buyer/findSeller'], { queryParams })
  }

  ngOnDestroy(): void {
    this.cookiesService.remove('selectedBusinessList');
    this.cookiesService.remove('selectedCountry');
    this.cookiesService.remove('selectedCity');
  }

  supplyLocations: any = [];
  getSellerSupplyLocations() {
    this.sellerService.getSellerSupplyLocationsBySellerId(this.sellerId).subscribe((resp: any) => {
      this.supplyLocations = resp.data;
    });
  }

  openCurrentPdf(pdf) {
    window.open(pdf.localFile);
  }

  fourtImage: any;
  orgImagesList: any = [];
  getOrgImages() {
    this.spinner.show();
    this.userProfileService.getSellerOrgImages(this.sellerId).subscribe(
      (resp: any) => {
        this.spinner.hide();
        let orimages = resp.data;
        this.orimageLength = orimages.length;
        if (this.orimageLength > 5) {
          this.showExtraOrImages = true;
          this.fourtImage = orimages[5];
        }
        this.orgImagesList = orimages;

      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
      }
    );
  }

  country_phone_code: string;
  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.companyInfoModel.companyInfo.country
    );
  }

  createProductionCapacitiesArray(data) {
    this.businessInfoModel.productionCapacities = [];
    if (data.businessListIds.length > 0) {
      if (data.businessListIds.indexOf(1) >= 0) {
        const business = data.sellerBusinessList.filter((business) => {
          return business.seller_business_list_id === 1;
        })[0];
        this.businessInfoModel.productionCapacities.push({
          name: business.businessName,
          businessId: 1,
          capacity: "",
          units: "",
          typeId: 0,
          isUnitSelected: false,
          isCapacityEntered: false,
        });
      }
      if (data.businessListIds.indexOf(2) >= 0) {
        const business = data.sellerBusinessList.filter((business) => {
          return business.seller_business_list_id === 2;
        })[0];
        data.sellerBusinessListSubTypes.map((type) => {
          this.businessInfoModel.productionCapacities.push({
            name:
              type.seller_business_list_sub_type_id === 4
                ? `${business.businessName} (${type.businessSubTypeName}-${type.other_name})`
                : `${business.businessName} (${type.businessSubTypeName})`,
            businessId: 2,
            capacity: "",
            units: "",
            typeId: type.seller_business_list_sub_type_id,
            isUnitSelected: false,
            isCapacityEntered: false,
          });
        });
      }
    }
  }

  currentImageIndex = 0;
  storeCurrentImageIndex(i) {
    this.currentImageIndex = i;
  }

  cfpSystems: any = [];
  getCFPSystems() {
    this.spinner.show();
    this.masterDataService.getCFPSystems().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.cfpSystems = resp.data;
        this.getCompanyInfo();
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }
}
