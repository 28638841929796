import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "angular2-cookie/services/cookies.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    public jwtHelper: JwtHelperService,
    private cookiesService: CookieService
  ) {}
  public isAuthenticated(): boolean {
    const token = this.cookiesService.get("gfp_jwt_token");
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }else{
      return false;
    }
  }
}
