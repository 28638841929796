import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

import { SignUpService } from "./../../services/sign-up.service";
import { MasterDataService } from "./../../services/master-data.service";
import { CountryPhoneCodesService } from "./../../services/country-phone-codes.service";
import { ToastrService } from "ngx-toastr";
import { ValidationsService } from "./../../services/validations.service";
@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  constructor(
    private signUpService: SignUpService,
    private masterDataService: MasterDataService,
    private router: Router,
    private countryCodesService: CountryPhoneCodesService,
    private toastrService: ToastrService,
    private validationsService: ValidationsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  fillFirstPart: boolean = true;
  signUpModel: any = {};

  ngOnInit() {
    this.getCountries();
    this.getLegalStatuses();
    this.getRoles();
  }

  countries: any = [];
  selectedCountry: any;

  country_phone_code: string;
  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.signUpModel.country
    );
  }

  getCountries() {
    this.spinner.show();
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.countries = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  roles: any = [];
  getRoles() {
    this.spinner.show();
    this.masterDataService.getRoles().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.roles = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  legalStatuses: any = [];
  getLegalStatuses() {
    this.spinner.show();
    this.masterDataService.getLegalStatuses().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.legalStatuses = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  cities: any = [];
  selectedCity: any;
  getCities() {
    this.spinner.show();
    this.masterDataService.getCities(this.signUpModel.country).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.cities = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  isCountrySelected = false;
  isCitySelected = false;
  validateSelectedCountry() {
    if (this.signUpModel.country) {
      this.isCountrySelected = true;
    } else {
      this.isCountrySelected = false;
    }
  }

  countriesListFormatter(data) {
    return data.country;
  }

  countrySelected(data) {
    this.signUpModel.country = data.country_code;
    this.getCities();
    this.getCountryPhoneCode();
  }

  clerPreviousSelectedCountry() {
    this.signUpModel.country = "";
    this.cities = [];
    this.selectedCity = "";
    this.signUpModel.city = "";
  }

  validateSelectedCity() {
    if (this.signUpModel.city) {
      this.isCitySelected = true;
    } else {
      this.isCitySelected = false;
    }
  }

  citiesListFormatter(data) {
    return data.city;
  }

  citySelected(data) {
    this.signUpModel.city = data.id;
  }

  clerPreviousSelectedCity() {
    this.signUpModel.city = "";
  }

  isButtonDisabled = false;
  signUpFormSubmit() {
    this.spinner.show();
    this.isButtonDisabled = true;
    // this.signUpService.signUp(this.signUpModel).subscribe(
    //   (resp: any) => {
    //     this.spinner.hide();
    //     this.toastrService.success(resp.message);
    //     this.router.navigate(["/login"]);
    //   },
    //   (err) => {
    //     this.spinner.hide();
    //     this.isButtonDisabled = false;
    //     this.toastr.error(err.error.message);
    //     if (err.status === 401) {
    //       this.router.navigate["/login"];
    //     } else if (err.status === 500) {
    //       this.router.navigate["/error"];
    //     }
    //   }
    // );
  }

  isUserAlreadyExist = false;
  checkIsUserAlreadyExist() {
    this.spinner.show();
    if (this.signUpModel.contact_person_email)
      this.signUpService
        .chechUserAlreadyExist(this.signUpModel.contact_person_email)
        .subscribe(
          (resp: any) => {
            this.spinner.hide();
            if (resp.data.isUserAlreadyExist) {
              this.isUserAlreadyExist = true;
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
  }

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
