import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { SellersService } from 'src/app/services/sellers.service';

@Component({
  selector: 'app-reviewed-sellers',
  templateUrl: './reviewed-sellers.component.html',
  styleUrls: ['./reviewed-sellers.component.css']
})
export class ReviewedSellersComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild("approveCloseBtn") approveCloseBtn: ElementRef;
  @ViewChild("rejectCloseBtn") rejectCloseBtn: ElementRef;

  constructor(
    private toastr: ToastrService,
    private adminService: AdminService,
    private sellerService: SellersService,
    private router: Router,
    private cookiesService: CookieService
  ) {}

  ngOnInit() {
    this.dtOptions = this.getDatatableOptions();
    this.getReviewedSellersList();
    this.cookiesService.remove("adminViewedFrom");
  }

  sellersList: any = [];
  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blftip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub Sellers submitted for review'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub Sellers submitted for review'
        }
      ]
    };
  }

  getReviewedSellersList(){
    this.adminService.getReviewedSellersList().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.sellersList = resp.data;
    })
  }

  showSellerPreview(userId){
    this.cookiesService.put("adminViewedFrom", "/admin/review/sellers");
    this.router.navigate(['/admin/sellerDetails'], { queryParams: { sellerId: userId } })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
