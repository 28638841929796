import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ValidationsService } from "./../../services/validations.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { MasterDataService } from "./../../services/master-data.service";
import { ToastrService } from "ngx-toastr";
import { CountryPhoneCodesService } from "./../../services/country-phone-codes.service";
import { NgForm } from "@angular/forms";
import { SellerProductsService } from "./../../services/seller-products.service";
import { HttpErrorResponse } from "@angular/common/http";
import _ from "lodash";

@Component({
  selector: "app-create-seller",
  templateUrl: "./create-seller.component.html",
  styleUrls: ["./create-seller.component.css"],
})
export class CreateSellerComponent implements OnInit {
  constructor(
    private validationsService: ValidationsService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private cookiesService: CookieService,
    private masterDataService: MasterDataService,
    private toastr: ToastrService,
    private countryCodesService: CountryPhoneCodesService,
    private sellerProductsService: SellerProductsService
  ) {}

  productId: number;
  loggedInUser: any;
  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.productId = +queryParams.get("productId");
      if (!this.productId) {
        this.router.navigate(["/error"]);
      }
      this.getContactsList();
      this.getSellerProductDetails();
    });
    this.loggedInUser = this.cookiesService.getObject("user");
    this.getCountries();
  }

  productDetails: any = {};
  getSellerProductDetails() {
    this.spinner.show();
    this.sellerProductsService
      .getSellerProductDetails(this.productId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.productDetails = resp.data;
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }
  farmModel: any = {};

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }

  alphabets_Space_NumberOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32 ||
      (keyCode >= 48 && keyCode <= 57)
    ) {
      return true;
    }
    return false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  countries: any = [];
  selectedCountry: string;
  selectedCountryObj: any;
  selectedCityObj: any;
  getCountries() {
    this.spinner.show();
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.countries = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  cities: any = [];
  selectedCity: string;
  getCities(country) {
    this.spinner.show();
    this.masterDataService.getCities(country).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.cities = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  isCountrySelected = false;
  isCitySelected = false;
  validateSelectedCountry() {
    if (this.selectedCountry) {
      this.isCountrySelected = true;
    } else {
      this.isCountrySelected = false;
    }
  }

  countriesListFormatter(data) {
    return data.country;
  }

  countrySelected(data) {
    this.selectedCountry = data.country_code;
    this.getCities(this.selectedCountry);
    this.getCountryPhoneCode();
  }

  country_phone_code: string;
  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.selectedCountry
    );
  }

  clerPreviousSelectedCountry() {
    this.selectedCountryObj = undefined;
    this.selectedCityObj = undefined;
    this.selectedCountry = "";
    this.cities = [];
    this.selectedCity = "";
  }

  validateSelectedCity() {
    if (this.selectedCity) {
      this.isCitySelected = true;
    } else {
      this.isCitySelected = false;
    }
  }

  citiesListFormatter(data) {
    return data.city;
  }

  citySelected(data) {
    this.selectedCity = data.id;
  }

  clerPreviousSelectedCity() {
    this.selectedCityObj = undefined;
    this.selectedCity = "";
  }

  contactsList: any = [];
  getContactsList() {
    this.spinner.show();
    this.sellerProductsService.getSellerContactsList(this.productId).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.contactsList = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  isCreate = false;
  isEdit = false;

  enableCreateForm() {
    this.isCreate = true;
  }

  clearPreviousSelections() {
    this.isCreate = false;
    this.isEdit = false;
    this.selectedCountryObj = undefined;
    this.selectedCityObj = undefined;
    this.isCitySelected = false;
    this.isCountrySelected = false;
    this.selectedCity = "";
    this.selectedCountry = "";
    this.cities = [];
  }

  //Add edit product Farms

  @ViewChild("farmForm")
  farmForm: NgForm;
  @ViewChild("farmModalCloseBtn") farmModalCloseBtn: ElementRef;
  onFarmModalClose() {
    this.farmModel = {};
    this.farmForm.resetForm();
    this.clearPreviousSelections();
    this.removeFarmImage();
  }

  isFarmModalSubmitDisabled = false;
  farmModalSubmit() {
    this.isFarmModalSubmitDisabled = true;
    this.spinner.show();
    if (this.isCreate) {
      if (!this.farmImage) {
        this.toastr.error("Please upload image");
        this.isFarmModalSubmitDisabled = false;
        this.spinner.hide();
        return;
      }
      const farmData = {
        ...this.farmModel,
        country: this.selectedCountry,
        city: this.selectedCity,
        seller_product_id: this.productId,
        userId: this.loggedInUser.id,
      };
      this.farmFormData = new FormData();
      this.farmFormData.append("files", this.farmImage);
      this.farmFormData.append("farmData", JSON.stringify(farmData));
      this.sellerProductsService
        .addSellerProductFarm(this.farmFormData)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isFarmModalSubmitDisabled = false;
            this.spinner.hide();
            this.farmModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isFarmModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    } else if (this.isEdit) {
      const farmData = {
        ..._.omit(this.farmModel, [
          "id",
          "created_on",
          "updated_on",
          "countryName",
          "cityName",
          "seller_product_id",
        ]),
        country: this.selectedCountry,
        city: this.selectedCity,
      };
      this.farmFormData = new FormData();
      if (this.farmImage) {
        this.farmFormData.append("files", this.farmImage);
      }
      this.farmFormData.append("farmData", JSON.stringify(farmData));
      this.sellerProductsService
        .updateSellerProductFarm(this.farmFormData, this.farmModel.id)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isFarmModalSubmitDisabled = false;
            this.spinner.hide();
            this.farmModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isFarmModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    }
  }

  editFarmData(farmData) {
    this.farmModel = { ...farmData };
    this.selectedCountryObj = {
      country_code: farmData.country,
      country: farmData.countryName,
    };
    this.getCities(farmData.country);
    this.selectedCityObj = { id: farmData, city: farmData.cityName };
    this.selectedCountry = farmData.country;
    this.selectedCity = farmData.city;
    this.farmImageLocal = farmData.image;
    this.isCitySelected = true;
    this.isCountrySelected = true;
    this.isEdit = true;
  }

  farmFormData: FormData = new FormData();
  @ViewChild("farmImageUploadEl")
  farmImageUploadEl: ElementRef;
  farmImage: any;
  farmImageLocal: any;
  uploadFarmImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.farmImage = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.farmImageLocal = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeFarmImage() {
    this.farmImage = "";
    this.farmImageLocal = "";
    this.farmImageUploadEl.nativeElement.value = "";
  }

  //Add edit Contact data

  @ViewChild("contactForm")
  contactForm: NgForm;
  @ViewChild("contactModalCloseBtn") contactModalCloseBtn: ElementRef;
  onContactModalClose() {
    this.contactModel = {};
    this.contactForm.resetForm();
    this.clearPreviousSelections();
  }

  isContactModalSubmitDisabled = false;
  contactModel: any = {};
  contactModalSubmit() {
    this.isContactModalSubmitDisabled = true;
    this.spinner.show();
    if (this.isCreate) {
      const contactData = {
        ...this.contactModel,
        country: this.selectedCountry,
        city: this.selectedCity,
        seller_product_id: this.productId,
        userId: this.loggedInUser.id,
      };
      this.sellerProductsService.addSellerProductContact(contactData).subscribe(
        (resp: any) => {
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
          this.isContactModalSubmitDisabled = false;
          this.spinner.hide();
          this.contactModalCloseBtn.nativeElement.click();
        },
        (err: HttpErrorResponse) => {
          this.isContactModalSubmitDisabled = false;
          this.spinner.hide();
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
    } else if (this.isEdit) {
      const contactData = {
        ..._.omit(this.contactModel, [
          "id",
          "created_on",
          "updated_on",
          "countryName",
          "cityName",
          "seller_product_id",
        ]),
        country: this.selectedCountry,
        city: this.selectedCity,
      };
      this.sellerProductsService
        .updateSellerProductContact(contactData, this.contactModel.id)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isContactModalSubmitDisabled = false;
            this.spinner.hide();
            this.contactModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isContactModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    }
  }

  editContactData(contactData) {
    this.contactModel = { ...contactData };
    this.selectedCountryObj = {
      country_code: contactData.country,
      country: contactData.countryName,
    };
    this.getCities(contactData.country);
    this.selectedCityObj = { id: contactData, city: contactData.cityName };
    this.selectedCountry = contactData.country;
    this.selectedCity = contactData.city;
    this.isCitySelected = true;
    this.isCountrySelected = true;
    this.isEdit = true;
  }

  //Add edit Supply location data

  @ViewChild("supplyLocationForm")
  supplyLocationForm: NgForm;
  @ViewChild("supplyLocationModalCloseBtn")
  supplyLocationModalCloseBtn: ElementRef;
  onSupplyLocationModalClose() {
    this.supplyLocationForm.resetForm();
    this.supplyLocationModel = { is_export_licensed: false };
    this.clearPreviousSelections();
  }

  isSupplyLocationModalSubmitDisabled = false;
  supplyLocationModel: any = { is_export_licensed: false };
  supplyLocationModalSubmit() {
    this.isSupplyLocationModalSubmitDisabled = true;
    this.spinner.show();
    if (this.isCreate) {
      const supplyLocationData = {
        ...this.supplyLocationModel,
        country: this.selectedCountry,
        city: this.selectedCity,
        seller_product_id: this.productId,
        userId: this.loggedInUser.id,
      };
      this.sellerProductsService
        .addSellerProductSupplyLocation(supplyLocationData)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isSupplyLocationModalSubmitDisabled = false;
            this.spinner.hide();
            this.supplyLocationModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isSupplyLocationModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    } else if (this.isEdit) {
      const supplyLocationData = {
        ..._.omit(this.supplyLocationModel, [
          "id",
          "created_on",
          "updated_on",
          "countryName",
          "cityName",
          "seller_product_id",
        ]),
        country: this.selectedCountry,
        city: this.selectedCity,
      };
      this.sellerProductsService
        .updateSellerProductSupplyLocation(
          supplyLocationData,
          this.supplyLocationModel.id
        )
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isSupplyLocationModalSubmitDisabled = false;
            this.spinner.hide();
            this.supplyLocationModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isSupplyLocationModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    }
  }

  editSupplyLocationData(supplyLocationData) {
    this.supplyLocationModel = { ...supplyLocationData };
    this.selectedCountryObj = {
      country_code: supplyLocationData.country,
      country: supplyLocationData.countryName,
    };
    this.getCities(supplyLocationData.country);
    this.selectedCityObj = {
      id: supplyLocationData.city,
      city: supplyLocationData.cityName,
    };
    this.selectedCountry = supplyLocationData.country;
    this.selectedCity = supplyLocationData.city;
    this.isCitySelected = true;
    this.isCountrySelected = true;
    this.isEdit = true;
  }

  //Add brand data
  @ViewChild("brandForm")
  brandForm: NgForm;
  @ViewChild("brandModalCloseBtn") brandModalCloseBtn: ElementRef;
  onBrandModalClose() {
    this.brandModel = {};
    this.brandForm.resetForm();
    this.clearPreviousSelections();
    this.removeBrandImage();
  }

  isBrandModalSubmitDisabled = false;
  brandModel: any = {};
  brandModalSubmit() {
    this.isBrandModalSubmitDisabled = true;
    this.spinner.show();
    if (this.isCreate) {
      if (!this.brandImage) {
        this.toastr.error("Please upload image");
        this.isBrandModalSubmitDisabled = false;
        this.spinner.hide();
        return;
      }
      const brandData = {
        ...this.brandModel,
        seller_product_id: this.productId,
        userId: this.loggedInUser.id,
      };
      this.brandFormData = new FormData();
      this.brandFormData.append("files", this.brandImage);
      this.brandFormData.append("brandData", JSON.stringify(brandData));
      this.sellerProductsService
        .addSellerProductBrand(this.brandFormData)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isBrandModalSubmitDisabled = false;
            this.spinner.hide();
            this.brandModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isBrandModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    } else if (this.isEdit) {
      if (!this.farmImage) {
        this.toastr.error("Please upload image");
        return;
      }
    }
  }

  brandFormData: FormData = new FormData();
  @ViewChild("brandImageUploadEl")
  brandImageUploadEl: ElementRef;
  brandImage: any;
  brandImageLocal: any;
  uploadBrandImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.brandImage = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.brandImageLocal = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeBrandImage() {
    this.brandImage = "";
    this.brandImageLocal = "";
    this.brandImageUploadEl.nativeElement.value = "";
  }

  // Add CFP certifications

  @ViewChild("cfpCertificationForm")
  cfpCertificationForm: NgForm;
  @ViewChild("cfpCertificationModalCloseBtn")
  cfpCertificationModalCloseBtn: ElementRef;
  oncfpCertificationModalClose() {
    this.cfpCertificationModel = {};
    this.cfpCertificationForm.resetForm();
    this.clearPreviousSelections();
    this.removeCfpCertificationImage();
  }

  isCfpCertificationModalSubmitDisabled = false;
  cfpCertificationModel: any = {};
  cfpCertificationModalSubmit() {
    this.isCfpCertificationModalSubmitDisabled = true;
    this.spinner.show();
    if (this.isCreate) {
      if (!this.cfpCertificationImage) {
        this.toastr.error("Please upload file");
        this.isCfpCertificationModalSubmitDisabled = false;
        this.spinner.hide();
        return;
      }
      const cfpCertificationData = {
        ...this.cfpCertificationModel,
        seller_product_id: this.productId,
        userId: this.loggedInUser.id,
      };
      this.cfpCertificationFormData = new FormData();
      this.cfpCertificationFormData.append("files", this.cfpCertificationImage);
      this.cfpCertificationFormData.append(
        "certificationData",
        JSON.stringify(cfpCertificationData)
      );
      this.sellerProductsService
        .addSellerProductCFPCertification(this.cfpCertificationFormData)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isCfpCertificationModalSubmitDisabled = false;
            this.spinner.hide();
            this.cfpCertificationModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isCfpCertificationModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    } else if (this.isEdit) {
      if (!this.farmImage) {
        this.toastr.error("Please upload file");
        return;
      }
    }
  }

  cfpCertificationFormData: FormData = new FormData();
  @ViewChild("cfpCertificationImageUploadEl")
  cfpCertificationImageUploadEl: ElementRef;
  cfpCertificationImage: any;
  cfpCertificationImageLocal: any;
  uploadCfpCertificationImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.cfpCertificationImage = event.target.files[0];
      if (event.target.files[0].type !== "application/pdf") {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          this.cfpCertificationImageLocal = (<FileReader>event.target).result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  removeCfpCertificationImage() {
    this.cfpCertificationImage = "";
    this.cfpCertificationImageLocal = "";
    this.cfpCertificationImageUploadEl.nativeElement.value = "";
  }

  // Add Organic accreditations

  @ViewChild("organicAccreditationForm")
  organicAccreditationForm: NgForm;
  @ViewChild("organicAccreditationModalCloseBtn")
  organicAccreditationModalCloseBtn: ElementRef;
  onOrganicAccreditationModalClose() {
    this.organicAccreditationModel = {};
    this.organicAccreditationForm.resetForm();
    this.clearPreviousSelections();
    this.removeOrganicAccreditationImage();
  }

  isOrganicAccreditationModalSubmitDisabled = false;
  organicAccreditationModel: any = {};
  organicAccreditationModalSubmit() {
    this.isOrganicAccreditationModalSubmitDisabled = true;
    this.spinner.show();
    if (this.isCreate) {
      if (!this.organicAccreditationImage) {
        this.toastr.error("Please upload file");
        this.isOrganicAccreditationModalSubmitDisabled = false;
        this.spinner.hide();
        return;
      }
      const organicAccreditationData = {
        ...this.organicAccreditationModel,
        seller_product_id: this.productId,
        userId: this.loggedInUser.id,
      };
      this.organicAccreditationFormData = new FormData();
      this.organicAccreditationFormData.append(
        "files",
        this.organicAccreditationImage
      );
      this.organicAccreditationFormData.append(
        "orgAccreditationData",
        JSON.stringify(organicAccreditationData)
      );
      this.sellerProductsService
        .addSellerProductOrganicAccreditation(this.organicAccreditationFormData)
        .subscribe(
          (resp: any) => {
            this.getSellerProductDetails();
            this.toastr.success(resp.message);
            this.isOrganicAccreditationModalSubmitDisabled = false;
            this.spinner.hide();
            this.organicAccreditationModalCloseBtn.nativeElement.click();
          },
          (err: HttpErrorResponse) => {
            this.isOrganicAccreditationModalSubmitDisabled = false;
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    } else if (this.isEdit) {
      if (!this.organicAccreditationImage) {
        this.toastr.error("Please upload file");
        return;
      }
    }
  }

  organicAccreditationFormData: FormData = new FormData();
  @ViewChild("organicAccreditationImageUploadEl")
  organicAccreditationImageUploadEl: ElementRef;
  organicAccreditationImage: any;
  organicAccreditationImageLocal: any;
  uploadOrganicAccreditationImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.organicAccreditationImage = event.target.files[0];
      if (event.target.files[0].type !== "application/pdf") {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          this.organicAccreditationImageLocal = (<FileReader>(
            event.target
          )).result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  removeOrganicAccreditationImage() {
    this.organicAccreditationImage = "";
    this.organicAccreditationImageLocal = "";
    this.organicAccreditationImageUploadEl.nativeElement.value = "";
  }

  openCurrentPdf(pdf) {
    window.open(pdf.file);
  }

  recordToDeleteId: any;
  storeDeletRecordId(id) {
    this.recordToDeleteId = id;
  }

  //Delete farm
  onDeleteFarmClose() {
    this.recordToDeleteId = "";
    this.farmDeleteModalCloseBtn.nativeElement.click();
  }

  isDeleteBtnDisabled = false;
  @ViewChild("farmDeleteModalCloseBtn") farmDeleteModalCloseBtn: ElementRef;
  deleteSellerProductFarm() {
    this.isDeleteBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .deleteSellerProductFarm(this.recordToDeleteId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.onDeleteFarmClose();
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }
  //Delete contact
  onDeleteContactClose() {
    this.recordToDeleteId = "";
    this.contactDeleteModalCloseBtn.nativeElement.click();
  }

  @ViewChild("contactDeleteModalCloseBtn")
  contactDeleteModalCloseBtn: ElementRef;
  deleteSellerProductContact() {
    this.isDeleteBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .deleteSellerProductContact(this.recordToDeleteId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.onDeleteContactClose();
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  //Delete supply location
  onDeleteSupplyLocationClose() {
    this.recordToDeleteId = "";
    this.supplyLocationDeleteModalCloseBtn.nativeElement.click();
  }

  @ViewChild("supplyLocationDeleteModalCloseBtn")
  supplyLocationDeleteModalCloseBtn: ElementRef;
  deleteSellerProductSupplyLocation() {
    this.isDeleteBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .deleteSellerProductSupplyLocation(this.recordToDeleteId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.onDeleteSupplyLocationClose();
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  //Delete brand
  onDeleteBrandClose() {
    this.recordToDeleteId = "";
    this.brandDeleteModalCloseBtn.nativeElement.click();
  }

  @ViewChild("brandDeleteModalCloseBtn")
  brandDeleteModalCloseBtn: ElementRef;
  deleteSellerProductBrand() {
    this.isDeleteBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .deleteSellerProductBrand(this.recordToDeleteId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.onDeleteBrandClose();
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  //Delete cfp certification
  onDeleteCfpCertificationClose() {
    this.recordToDeleteId = "";
    this.cfpCertificationDeleteModalCloseBtn.nativeElement.click();
  }

  @ViewChild("cfpCertificationDeleteModalCloseBtn")
  cfpCertificationDeleteModalCloseBtn: ElementRef;
  deleteSellerProductCfpCertification() {
    this.isDeleteBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .deleteSellerProductCFPCertification(this.recordToDeleteId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.onDeleteCfpCertificationClose();
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  //Delete organic accreditation
  onDeleteOrganicAccreditationClose() {
    this.recordToDeleteId = "";
    this.organicAccreditationDeleteModalCloseBtn.nativeElement.click();
  }

  @ViewChild("organicAccreditationDeleteModalCloseBtn")
  organicAccreditationDeleteModalCloseBtn: ElementRef;
  deleteSellerProductOrganicAccreditation() {
    this.isDeleteBtnDisabled = true;
    this.spinner.show();
    this.sellerProductsService
      .deleteSellerProductOrganicAccreditation(this.recordToDeleteId)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.onDeleteOrganicAccreditationClose();
          this.getSellerProductDetails();
          this.toastr.success(resp.message);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isDeleteBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }
}
