import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { SellersService } from './../../services/sellers.service';
import { Router } from '@angular/router';
import { CookieService } from 'angular2-cookie/services/cookies.service';

@Component({
  selector: 'app-seller-products-rejected',
  templateUrl: './seller-products-rejected.component.html',
  styleUrls: ['./seller-products-rejected.component.css']
})
export class SellerProductsRejectedComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild("approveCloseBtn") approveCloseBtn: ElementRef;
  @ViewChild("rejectCloseBtn") rejectCloseBtn: ElementRef;

  constructor(
    private toastr: ToastrService,
    private adminService: AdminService,
    private sellerService: SellersService,
    private router: Router,
    private cookiesService: CookieService
  ) {}

  ngOnInit() {
    this.dtOptions = this.getDatatableOptions();
    this.getRejectedSellers();
    this.cookiesService.remove("adminViewedFrom");
  }

  sellersList: any = [];
  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blftip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub rejected Sellers'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub rejected Sellers'
        }
      ]
    };
  }

  getRejectedSellers(){
    this.adminService.getRejectedSellers().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.sellersList = resp.data;
    })
  }

  showSellerPreview(userId){
    this.cookiesService.put("adminViewedFrom", "/admin/rejected/sellers");
    this.router.navigate(['/admin/sellerDetails'], { queryParams: { sellerId: userId } })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
