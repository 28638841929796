import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { CountryPhoneCodesService } from "./../../services/country-phone-codes.service";
import { BuyerService } from "./../../services/buyer.service";
import { MasterDataService } from "./../../services/master-data.service";
import { HttpErrorResponse } from "@angular/common/http";
import _ from "lodash";
import { UserProfileService } from './../../services/user-profile.service';

@Component({
  selector: "app-buyer-profile",
  templateUrl: "./buyer-profile.component.html",
  styleUrls: ["./buyer-profile.component.css"],
})
export class BuyerProfileComponent implements OnInit {
  constructor(
    private cookiesService: CookieService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private countryCodesService: CountryPhoneCodesService,
    private buyerService: BuyerService,
    private masterDataService: MasterDataService,
    private userProfileService: UserProfileService
  ) {}

  loggedInUser: any = {};
  ngOnInit() {
    this.loggedInUser = this.cookiesService.getObject("user");
    this.getCountries();
    this.getUserDetails();
    this.getNatureOfBusinessList();
  }

  userData: any = {};
  countries: any = [];
  country_phone_code: string;
  selectedCountry: any = {};
  selectedBusinessList: any = [];
  getUserDetails() {
    this.spinner.show();
    this.buyerService.getBuyerDetails(this.loggedInUser.id).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.userData = resp.data.buyerInfo;
        this.userProfileService.userProfileImage.next({
          profileUrl:this.userData.profile_image,
          userName:this.userData.contact_person_first_name + " " + this.userData.contact_person_last_name,
        });
        this.selectedBusinessList = resp.data.businessListIds;
        this.getCities();
        this.selectedCountry = {
          country_code: this.userData.country,
          country: this.userData.countryName,
        };
        this.selectedCity = {
          id: this.userData.city,
          city: `${this.userData.cityName} (${this.userData.state})`,
        };
        this.isCountrySelected = true;
        this.isCitySelected = true;
        this.getCountryPhoneCode();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.userData.country
    );
  }

  natureOfBusinessList: any = [];
  getNatureOfBusinessList() {
    this.spinner.show();
    this.masterDataService.getBuyerNatureOfBusinessList().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.natureOfBusinessList = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  getCountries() {
    this.spinner.show();
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.countries = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  legalStatuses: any = [];
  getLegalStatuses() {
    this.spinner.show();
    this.masterDataService.getLegalStatuses().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.legalStatuses = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  cities: any = [];
  selectedCity: any;
  getCities() {
    this.spinner.show();
    this.masterDataService.getCities(this.userData.country).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.cities = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  isCountrySelected = false;
  isCitySelected = false;
  validateSelectedCountry() {
    if (this.userData.country) {
      this.isCountrySelected = true;
    } else {
      this.isCountrySelected = false;
    }
  }

  countriesListFormatter(data) {
    return data.country;
  }

  countrySelected(data) {
    this.userData.country = data.country_code;
    this.getCities();
    this.getCountryPhoneCode();
  }

  clerPreviousSelectedCountry() {
    this.userData.country = "";
    this.cities = [];
    this.selectedCity = "";
    this.userData.city = "";
  }

  validateSelectedCity() {
    if (this.userData.city) {
      this.isCitySelected = true;
    } else {
      this.isCitySelected = false;
    }
  }

  citiesListFormatter(data) {
    return data.city;
  }

  citySelected(data) {
    this.userData.city = data.id;
  }

  clerPreviousSelectedCity() {
    this.userData.city = "";
  }

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  buyerUpdateSchemaParams = [
    "company_name",
    "phone",
    "website",
    "contact_person_first_name",
    "contact_person_last_name",
    "contact_person_name_title",
    "country",
    "city",
    "nature_of_business_list",
    "nature_of_business_list_otherName",
    "status"
  ];

  isUpdateBuyerDetailsButtonDisabled = false;
  isFormEditable = false;
  buyerUpdateDetailsFormSubmit() {
    this.spinner.show();
    this.isUpdateBuyerDetailsButtonDisabled = true;
    const dataToSubmit = {
      ..._.pick(this.userData, [
        ...this.buyerUpdateSchemaParams
      ]),
      nature_of_business_list: this.selectedBusinessList
    };
    this.userProfileService
      .updateBuyerDetails(this.loggedInUser.id, dataToSubmit)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.toastr.success(resp.message)
          this.isUpdateBuyerDetailsButtonDisabled = false;
          this.isFormEditable = false;
          this.getUserDetails();
          this.userProfileService.userDataChanged.next();
        },
        (err) => {
          this.isUpdateBuyerDetailsButtonDisabled = false;
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
  }

  enableFormEdit(){
    this.isFormEditable = true;
  }

  cancelFormEdit(){
    this.isFormEditable = false;
    this.getUserDetails();
  }

  formImageData: FormData;
  @ViewChild("fileUpload")
  selectedFileElement: ElementRef;
  uploadProfilePic(event: any) {
    if (event.target.files && event.target.files[0]) {
      const fileDetails = event.target.files[0];
      this.formImageData = new FormData();
      this.formImageData.append("files", fileDetails);
      this.spinner.show();
      this.userProfileService
        .uploadUserProfilePic(this.formImageData)
        .subscribe(
          (resp: any) => {
            this.spinner.hide();
            this.selectedFileElement.nativeElement.value = "";
            this.getUserDetails();
            this.formImageData = new FormData();
            this.userProfileService.userDataChanged.next();
          },
          (err) => {
            this.spinner.hide();
            this.selectedFileElement.nativeElement.value = "";
            this.formImageData = new FormData();
            this.toastr.error(err.error.message);
          }
        );
    }
  }

  onBusinessListValueChange(){
    if(this.selectedBusinessList.indexOf(3) < 0){
      this.userData.nature_of_business_list_otherName = null;
    }
  }
}
