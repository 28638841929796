import { HomePageComponent } from './Components/home-page/home-page.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UnAuthorizedComponent } from "./un-authorized/un-authorized.component";
import { LandingComponent } from "./Components/landing/landing.component";
import { LoginComponent } from "./Components/login/login.component";
import { SignUpComponent } from "./Components/sign-up/sign-up.component";
import { FindSellerComponent } from "./Components/find-seller/find-seller.component";
import { SellerDetailsComponent } from "./Components/seller-details/seller-details.component";
import { BuyerLandingComponent } from "./Components/buyer-landing/buyer-landing.component";
import { SellerLandingComponent } from "./Components/seller-landing/seller-landing.component";
import { AdminLandingComponent } from "./Components/admin-landing/admin-landing.component";
import { WaitingForApprovalComponent } from "./Components/waiting-for-approval/waiting-for-approval.component";
import { SellerProductsComponent } from "./Components/seller-products/seller-products.component";
import { CreateSellerComponent } from "./Components/create-seller/create-seller.component";
import { SellerProductsApprovalsComponent } from "./Components/seller-products-approvals/seller-products-approvals.component";
import { BuyerApprovalsComponent } from "./Components/buyer-approvals/buyer-approvals.component";
import { SellerProductsRejectedComponent } from "./Components/seller-products-rejected/seller-products-rejected.component";
import { BuyerRejectedComponent } from "./Components/buyer-rejected/buyer-rejected.component";
import { ProfileDetailsComponent } from "./Components/profile-details/profile-details.component";
import { ErrorComponent } from "./Components/error/error.component";
import { RoleGuardService } from "./guards/role-guard.service";
import { PendingBuyersComponent } from "./Components/pending-buyers/pending-buyers.component";
import { PendingSellerProductsComponent } from "./Components/pending-seller-products/pending-seller-products.component";
import { BuyerRfpComponent } from "./Components/buyer-rfp/buyer-rfp.component";
import { BuyerRfpListComponent } from "./Components/buyer-rfp-list/buyer-rfp-list.component";
import { SellerRegistrationComponent } from "./Components/seller-registration/seller-registration.component";
import { BuyerRegistrationComponent } from "./Components/buyer-registration/buyer-registration.component";
import { BuyerApprovalRequestComponent } from "./Components/buyer-approval-request/buyer-approval-request.component";
import { SellerProductInfoComponent } from "./Components/seller-product-info/seller-product-info.component";
import { BuyerProfileComponent } from "./Components/buyer-profile/buyer-profile.component";
import { SellerDetailsPreviewComponent } from "./Components/seller-details-preview/seller-details-preview.component";
import { TearmsAndConditionsComponent } from "./Components/tearms-and-conditions/tearms-and-conditions.component";
import { ReviewedSellersComponent } from "./Components/reviewed-sellers/reviewed-sellers.component";

const routes: Routes = [
  { path: "unAuthorized", component: UnAuthorizedComponent },
  { path: "error", component: ErrorComponent },
  {path: "Home", component: HomePageComponent},
//   {path: "Home", component: HomePageComponent,
//   children: [
//     { path: "", redirectTo: "Home", pathMatch: "full" },
//   ]
// },
  {
    path: "",
    component: LandingComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "signUp", component: SignUpComponent },
      { path: "seller-signUp", component: SellerRegistrationComponent },
      { path: "buyer-registration", component: BuyerRegistrationComponent },
      {
        path: "buyerRegistrationSuccess",
        component: BuyerApprovalRequestComponent,
      },
      {
        path: "sellerRegistrationSuccess",
        component: WaitingForApprovalComponent,
      },
      { path: "TearmsAndConditions", component: TearmsAndConditionsComponent },

      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  {
    path: "buyer",
    canActivate: [RoleGuardService],
    data: {
      expectedRole: 1,
    },
    component: BuyerLandingComponent,
    children: [
      { path: "findSeller", component: FindSellerComponent },
      { path: "sellerDetails", component: SellerDetailsPreviewComponent },
      { path: "rfp", component: BuyerRfpComponent },
      { path: "profile", component: BuyerProfileComponent },
    ],
  },
  {
    path: "seller",
    component: SellerLandingComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: 2,
    },
    children: [
      { path: "products", component: SellerProductsComponent },
      { path: "productDetails", component: CreateSellerComponent },
      { path: "profile", component: ProfileDetailsComponent },
      { path: "productInfo", component: SellerProductInfoComponent },
      { path: "productPreview", component: SellerDetailsPreviewComponent },
    ],
  },
  {
    path: "admin",
    component: AdminLandingComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: 3,
    },
    children: [
      { path: "buyer-rfp", component: BuyerRfpListComponent },
      { path: "pending/sellers", component: PendingSellerProductsComponent },
      { path: "pending/buyers", component: PendingBuyersComponent },
      { path: "rejected/sellers", component: SellerProductsRejectedComponent },
      { path: "rejected/buyers", component: BuyerRejectedComponent },
      { path: "approved/sellers", component: SellerProductsApprovalsComponent },
      { path: "approved/buyers", component: BuyerApprovalsComponent },
      { path: "review/sellers", component: ReviewedSellersComponent },
      { path: "productDetails", component: SellerDetailsComponent },
      { path: "sellerDetails", component: SellerDetailsPreviewComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
