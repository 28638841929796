import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellersService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  onSellerStatusChange: Subject<string> = new Subject();
  getCompanyInfo(){
    return this.httpMasterService.getService('sellers/companyInfo')
  }

  getCompanyInfoBySellerId(sellerId){
    return this.httpMasterService.getService(`sellers/companyInfo/${sellerId}`)
  }

  getSellerBusinessInfo(){
    return this.httpMasterService.getService('sellers/businessInfo')
  }

  getSellerBusinessInfoBySellerId(sellerId){
    return this.httpMasterService.getService(`sellers/businessInfo/${sellerId}`)
  }

  getSellerSupplyLocations(){
    return this.httpMasterService.getService('sellers/supplyLocations')
  }

  getSellerSupplyLocationsBySellerId(sellerId){
    return this.httpMasterService.getService(`sellers/supplyLocations/${sellerId}`)
  }

  addSellerSupplyLocations(data){
    return this.httpMasterService.postService('sellers/supplyLocations', data)
  }

  addSellerBusinessInfo(data){
    return this.httpMasterService.postService('sellers/businessInfo', data)
  }

  submitSellerForAdminApproval(){
    return this.httpMasterService.postService('sellers/submitForAdminApproval', {})
  }

  updateSellerBusinessInfo(data, userId){
    return this.httpMasterService.patchService(`sellers/businessInfo/${userId}`, data)
  }

  updateSellerSupplyLocations(data, id){
    return this.httpMasterService.patchService(`sellers/supplyLocations/${id}`, data)
  }

  deleteSellerSupplyLocations(id){
    return this.httpMasterService.deleteService(`sellers/supplyLocations/${id}`)
  }

  updateSellerCompanyInfo(data, userId){
    return this.httpMasterService.patchService(`sellers/profile/${userId}`, data)
  }

  getCompanyStatusInfo(){
    return this.httpMasterService.getService('sellers/sellerStatusInfo')
  }

  getBuyerCompanyStatusInfo(){
    return this.httpMasterService.getService('buyers/getBuyerCompanyInfo')
  }

  deleteSellerOrgImage(data){
    return this.httpMasterService.postService('sellers/deleteOrgImage', data)
  }
}
