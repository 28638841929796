import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BuyerService } from "src/app/services/buyer.service";
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: "app-buyer-rfp",
  templateUrl: "./buyer-rfp.component.html",
  styleUrls: ["./buyer-rfp.component.css"],
})
export class BuyerRfpComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private buyerService: BuyerService
  ) {}

  ngOnInit() {}

  @ViewChild("rfpDocumentEl")
  rfpDocumentEl: ElementRef;
  @ViewChild("f")
  rfpForm: NgForm;
  rfpModel: any = {};
  isRfpSubmitBtnDisabled = false;
  submitBuyerRfpForm() {
    if (!this.rfpDocumentFile) {
      this.toastr.error("Upload document");
      return;
    }
    this.isRfpSubmitBtnDisabled = true;
    const dataToSend = new FormData();
    dataToSend.append("rfpData", JSON.stringify(this.rfpModel));
    dataToSend.append("files[]", this.rfpDocumentFile);
    this.buyerService.submitBuyerRfpForm(dataToSend).subscribe((resp: any)=>{
      this.toastr.success(resp.message);
      this.isRfpSubmitBtnDisabled = false;
      this.rfpForm.resetForm();
      this.rfpModel = {};
      this.removeFile();
    }, (err: HttpErrorResponse)=>{
      this.isRfpSubmitBtnDisabled = false;
    })
  }

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  rfpDocumentFile: any;
  selectedFiles: FileList;
fileName: string;
  uploadRfpDocument(event: any, brand, i) {
    if (event.target.files && event.target.files[0]) {
      this.rfpDocumentFile = event.target.files[0];
      this.selectedFiles = event.target.files;
      this.fileName = this.selectedFiles[0].name;
      console.log('selectedFiles: ' + this.fileName );
    }
  }

  removeFile() {
    this.rfpDocumentEl.nativeElement.value = "";
    this.rfpDocumentFile = "";
  }
}
