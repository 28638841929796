import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-approval-request',
  templateUrl: './buyer-approval-request.component.html',
  styleUrls: ['./buyer-approval-request.component.css']
})
export class BuyerApprovalRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
