import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { UserProfileService } from "./../../services/user-profile.service";
import { PendingsService } from "./../../services/pendings.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AdminService } from './../../services/admin.service';
import { BuyerService } from './../../services/buyer.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-pending-buyers",
  templateUrl: "./pending-buyers.component.html",
  styleUrls: ["./pending-buyers.component.css"],
})
export class PendingBuyersComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  @ViewChild("approveCloseBtn") approveCloseBtn: ElementRef;
  @ViewChild("rejectCloseBtn") rejectCloseBtn: ElementRef;

  constructor(
    private httpClient: HttpClient,
    private userProfileService: UserProfileService,
    private pendingsService: PendingsService,
    private toastr: ToastrService,
    private router: Router,
    private adminService: AdminService,
    private buyerService: BuyerService
  ) {}

  ngOnInit() {
    this.dtOptions = this.getDatatableOptions();
    this.getBuyersForAdminApproval();
    this.pendingsService.onBuyerSellerStatusChange.next();
  }

  buyersList: any = [];
  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blftip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub pending Buyers'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub pending Buyers'
        }
      ]
    };
  }

  getBuyersForAdminApproval(){
    this.adminService.getBuyersForAdminApproval().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.buyersList = resp.data;
    })
  }

  buyerDetails: any = {};
  viewBuyerDetails(id) {
    this.buyerService.getBuyerDetails(id).subscribe(
      (resp: any) => {
        this.buyerDetails = resp.data;
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  currentBuyerId: any;
  storeCurrentBuyerId(id) {
    this.currentBuyerId = id;
  }

  removeCurrentBuyerId() {
    this.currentBuyerId = "";
    this.reasonOfReject = "";
    this.approveCloseBtn.nativeElement.click();
    this.rejectCloseBtn.nativeElement.click();
  }

  isApprovalBtnDisabled = false;
  approveBuyer() {
    this.isApprovalBtnDisabled = true;
    const data = {
      id: this.currentBuyerId,
    };
    this.pendingsService.approveBuyer(data).subscribe(
      (resp: any) => {
        this.isApprovalBtnDisabled = false;
        this.toastr.success("Buyer approved successfully.");
        this.approveCloseBtn.nativeElement.click();
        this.getBuyersForAdminApproval();
        this.pendingsService.onBuyerSellerStatusChange.next();
      },
      (err) => {
        this.isApprovalBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  isRejectBtnDisabled = false;
  reasonOfReject: string;
  rejectBuyer() {
    if(!this.reasonOfReject || !this.reasonOfReject.trim()){
      this.toastr.error("Reason for rejecting is required");
      return;
    }
    this.isRejectBtnDisabled = true;
    const data = {
      id: this.currentBuyerId,
      reason: this.reasonOfReject
    };
    this.pendingsService.rejectBuyer(data).subscribe(
      (resp: any) => {
        this.isRejectBtnDisabled = false;
        this.toastr.success("Buyer rejected successfully.");
        this.approveCloseBtn.nativeElement.click();
        this.getBuyersForAdminApproval();
        this.pendingsService.onBuyerSellerStatusChange.next();
      },
      (err) => {
        this.isRejectBtnDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }
}
