import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UserProfileService } from "./../../services/user-profile.service";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { CountryPhoneCodesService } from "./../../services/country-phone-codes.service";
import { MasterDataService } from "./../../services/master-data.service";
import _ from "lodash";
import { ValidationsService } from "./../../services/validations.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { NgForm } from '@angular/forms';

@Component({
  selector: "app-profile-details",
  templateUrl: "./profile-details.component.html",
  styleUrls: ["./profile-details.component.css"],
})
export class ProfileDetailsComponent implements OnInit {
  selectedCountry: any;
  @ViewChild("changePasswordCloseBtn") changePasswordCloseBtn: ElementRef;

  constructor(
    private userProfileService: UserProfileService,
    private cookiesService: CookieService,
    private countryCodesService: CountryPhoneCodesService,
    private masterDataService: MasterDataService,
    private validationsService: ValidationsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  isFormEditable = false;
  loggedInUser: any;
  buyerUpdateSchemaParams = [
    "org_name",
    "org_phone",
    "org_telephone",
    "org_legal_status_id",
    "contact_person_first_name",
    "contact_person_last_name",
    "contact_person_name_title",
    "contact_person_phone",
    "country",
    "city",
    "address",
  ];
  ngOnInit() {
    this.loggedInUser = this.cookiesService.getObject("user");
    this.getUserDetails();
    this.getLegalStatuses();
    this.getCountries();
    // this.getOrgImages();
  }

  cancelFormEdit() {
    this.isFormEditable = false;
    this.userData = { ...this.userDetailsMasterData };
  }

  makeFomEditable() {
    this.isFormEditable = true;
  }

  userData: any = {};
  countries: any = [];
  country_phone_code: string;
  userDetailsMasterData: any = {};
  getUserDetails() {
    this.spinner.show();
    this.userProfileService.getUserDetails(this.loggedInUser.id).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.userData = resp.data;
        this.userDetailsMasterData = { ...resp.data };
        this.getCities();
        this.selectedCountry = {
          country_code: this.userData.country,
          country: "India",
        };
        this.selectedCity = { id: this.userData.city, city: "Hyderabad" };
        this.isCountrySelected = true;
        this.isCitySelected = true;
        this.getCountryPhoneCode();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.userData.country
    );
  }

  getCountries() {
    this.spinner.show();
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.countries = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  legalStatuses: any = [];
  getLegalStatuses() {
    this.spinner.show();
    this.masterDataService.getLegalStatuses().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.legalStatuses = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  cities: any = [];
  selectedCity: any;
  getCities() {
    this.spinner.show();
    this.masterDataService.getCities(this.userData.country).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.cities = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  isCountrySelected = false;
  isCitySelected = false;
  validateSelectedCountry() {
    if (this.userData.country) {
      this.isCountrySelected = true;
    } else {
      this.isCountrySelected = false;
    }
  }

  countriesListFormatter(data) {
    return data.country;
  }

  countrySelected(data) {
    this.userData.country = data.country_code;
    this.getCities();
    this.getCountryPhoneCode();
  }

  clerPreviousSelectedCountry() {
    this.userData.country = "";
    this.cities = [];
    this.selectedCity = "";
    this.userData.city = "";
  }

  validateSelectedCity() {
    if (this.userData.city) {
      this.isCitySelected = true;
    } else {
      this.isCitySelected = false;
    }
  }

  citiesListFormatter(data) {
    return data.city;
  }

  citySelected(data) {
    this.userData.city = data.id;
  }

  clerPreviousSelectedCity() {
    this.userData.city = "";
  }

  isUpdateBtnDisabled = false;
  updateUserDetails() {
    let dataToSubmit;
    let userType;
    if (this.loggedInUser.role === 1) {
      dataToSubmit = _.pick(this.userData, this.buyerUpdateSchemaParams);
      userType = "buyers";
    } else if (this.loggedInUser.role === 2) {
      dataToSubmit = _.pick(this.userData, [
        ...this.buyerUpdateSchemaParams,
        "org_summary",
      ]);
      userType = "sellers";
    }
    this.isUpdateBtnDisabled = true;
    this.spinner.show();
    this.userProfileService
      .updateUserDetails(this.loggedInUser.id, userType, dataToSubmit)
      .subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.isUpdateBtnDisabled = false;
          this.isFormEditable = false;
          this.getUserDetails();
        },
        (err) => {
          this.isUpdateBtnDisabled = false;
          this.spinner.hide();
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  formImageData: FormData = new FormData();
  @ViewChild("fileUpload")
  selectedFileElement: ElementRef;
  uploadProfilePic(event: any) {
    if (event.target.files && event.target.files[0]) {
      const fileDetails = event.target.files[0];
      this.formImageData.append("files", fileDetails);
      this.spinner.show();
      this.userProfileService
        .uploadUserProfilePic(this.formImageData)
        .subscribe(
          (resp: any) => {
            this.spinner.hide();
            this.selectedFileElement.nativeElement.value = "";
            this.getUserDetails();
            this.formImageData = new FormData();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            if (err.status === 401) {
              this.router.navigate["/login"];
            } else if (err.status === 500) {
              this.router.navigate["/error"];
            }
          }
        );
    }
  }

  orgImageData: FormData = new FormData();
  @ViewChild("orgImages")
  orgImages: ElementRef;
  uploadOrgImages(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0;i < event.target.files.length; i++) {
        const fileDetails = event.target.files[i];
        this.orgImageData.append("files[]", fileDetails);
      }
      this.orgImageData.append("oldImages", JSON.stringify(this.orgImagesList));
      this.orgImageData.append("imagesToDelete", JSON.stringify([]));
      this.spinner.show();
      this.userProfileService.uploadOrgImages(this.orgImageData).subscribe(
        (resp: any) => {
          this.spinner.hide();
          this.orgImages.nativeElement.value = "";
          this.orgImageData = new FormData();
          this.getOrgImages();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
    }
  }

  deletOrgImage(index) {
    this.spinner.show();
    const oldImages = [...this.orgImagesList];
    const imageToDelete = oldImages.splice(index, 1);
    this.orgImageData.append("oldImages", JSON.stringify(oldImages));
    this.orgImageData.append("imagesToDelete", JSON.stringify(imageToDelete));
    this.spinner.show();
    this.userProfileService.uploadOrgImages(this.orgImageData).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.getOrgImages();
        this.orgImageData = new FormData();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changePasswordModel: any = {};
  isCpBtnDisabled = false;
  @ViewChild("upf")
  updatePasswordForm: NgForm;
  changePassword() {
    this.spinner.show();
    this.isCpBtnDisabled = true;
    this.userProfileService
      .changePasswordService({
        ...this.changePasswordModel,
        id: this.loggedInUser.id,
      })
      .subscribe(
        (resp: any) => {
          this.toastr.success("Password changed successfully.");
          this.changePasswordCloseBtn.nativeElement.click();
          this.spinner.hide();
          this.isCpBtnDisabled = false;
        },
        (err) => {
          this.spinner.hide();
          this.isCpBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  clerChangePasswordModal() {
    this.changePasswordModel = {};
    this.updatePasswordForm.resetForm();
  }

  orgImagesList: any = [];
  getOrgImages() {
    this.spinner.show();
    this.userProfileService.getSellerOrgImages(this.loggedInUser.id).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.orgImagesList = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
      }
    );
  }
}
