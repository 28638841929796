import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting-for-approval',
  templateUrl: './waiting-for-approval.component.html',
  styleUrls: ['./waiting-for-approval.component.css']
})
export class WaitingForApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
