import { Component, OnInit, ViewChild, ElementRef,HostListener } from "@angular/core";
import { CookieService } from "angular2-cookie/services/cookies.service";
import { Router } from "@angular/router";
import { UserProfileService } from "./../../services/user-profile.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SellersService } from "./../../services/sellers.service";

declare var $;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("changePasswordCloseBtn") changePasswordCloseBtn: ElementRef;

  profileData: any;
  constructor(
    private cookiesService: CookieService,
    private router: Router,
    private userProfileService: UserProfileService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sellerService: SellersService
  ) {
    // this.userProfileService.userProfileImage.subscribe(data =>{
    //   data.profileUrl = data.profileUrl ? data.profileUrl : '../../../assets/images/user.jpg';
    //   data.userName = data.userName ? data.userName : 'User Name';
    //   this.profileData = data
    // });
  }

  user: any;
  ngOnInit() {
    this.userProfileService.userDataChanged.subscribe((resp) => {
      if (this.user.role === 2) {
        this.getSellerStatusInfo();
      }
      if (this.user.role === 1) {
        this.getBuyerStatusInfo();
      }
    });

    this.sellerService.onSellerStatusChange.subscribe((resp) => {
      if (this.user.role === 2) {
        this.getSellerStatusInfo();
      }
    });
    this.user = this.cookiesService.getObject("user");
    if (this.user.role === 2) {
      this.getSellerStatusInfo();
    }
    if (this.user.role === 1) {
      this.getBuyerStatusInfo();
    }
    $(document).ready(function () {
      $("ul li a").click(function () {
        $("li a").removeClass("active");
        $(this).addClass("active");
      });
    });
  }

  logOut() {
    this.cookiesService.removeAll();
    this.router.navigate(["/login"]);
  }

  changePasswordModel: any = {};
  isCpBtnDisabled = false;
  @ViewChild("upf")
  updatePasswordForm: NgForm;
  changePassword() {
    this.spinner.show();
    this.isCpBtnDisabled = true;
    this.userProfileService
      .changePasswordService({
        ...this.changePasswordModel,
        id: this.user.id,
      })
      .subscribe(
        (resp: any) => {
          this.toastr.success("Password changed successfully.");
          this.changePasswordCloseBtn.nativeElement.click();
          this.spinner.hide();
          this.isCpBtnDisabled = false;
        },
        (err) => {
          this.spinner.hide();
          this.isCpBtnDisabled = false;
          this.toastr.error(err.error.message);
          if (err.status === 401) {
            this.router.navigate["/login"];
          } else if (err.status === 500) {
            this.router.navigate["/error"];
          }
        }
      );
  }

  clerChangePasswordModal() {
    this.changePasswordModel = {};
    this.updatePasswordForm.resetForm();
  }

  companyName: string;
  companyLogo: string = "./../../../assets/images/user.jpg";
  sellerStatus: string;
  getSellerStatusInfo() {
    this.sellerService.getCompanyStatusInfo().subscribe((resp: any) => {
      this.companyName = resp.data.companyName;
      if (resp.data.profileImage) {
        this.companyLogo = resp.data.profileImage;
      }
      this.sellerStatus = resp.data.status;
    });
  }

  getBuyerStatusInfo() {
    this.sellerService.getBuyerCompanyStatusInfo().subscribe((resp: any) => {
      this.companyName = resp.data.companyName;
      if (resp.data.profileImage) {
        this.companyLogo = resp.data.profileImage;
      }
    });
  }

  // @HostListener('document:click')
  // clickout() {
  //   $( "#navBarId" ).removeClass( "in" )
  // }
}
