import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { map, filter, catchError, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class HttpMasterService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getService(endPoint: string) {
    return this.httpClient
      .get(`${environment.base_url}${endPoint}`)
      .pipe(map((res: Response) => res));
  }
  postService(endPoint: string, data) {
    return this.httpClient
      .post(`${environment.base_url}${endPoint}` , data)
      .pipe(map((res: Response) => res));
  }

  patchService(endPoint: string, data) {
    return this.httpClient
      .patch(`${environment.base_url}${endPoint}` , data)
      .pipe(map((res: Response) => res));
  }

  deleteService(endPoint: string) {
    return this.httpClient
      .delete(`${environment.base_url}${endPoint}`)
      .pipe(map((res: Response) => res));
  }

}
