import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from './../../services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-buyer-rfp-list',
  templateUrl: './buyer-rfp-list.component.html',
  styleUrls: ['./buyer-rfp-list.component.css']
})
export class BuyerRfpListComponent implements OnInit {

  constructor(
    private adminService: AdminService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.dtOptions = this.getDatatableOptions();
    this.getBuyerRfpsList();
  }

  getDatatableOptions() {
    return {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      dom: "Blftip",
      buttons: [
        {
          extend: 'print',
          text: 'Print',
          title: 'Cagefree hub Buyer rfps list'
        },
        {
          extend: 'excel',
          text: 'Excel',
          title: 'Cagefree hub Buyer rfps list'
        }
      ]
    };
  }

  rfpsList: any = [];
  getBuyerRfpsList(){
    this.spinner.show();
    this.adminService.getBuyerRfpsList().subscribe((resp: any)=>{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
      this.rfpsList = resp.data;
      this.spinner.hide();
    }, (err: HttpErrorResponse)=>{
      this.rfpsList = [];
      this.spinner.hide();
    })
  }

  openCurrentPdf(pdf) {
    window.open(pdf);
  }

  currentRfp: any = {};
  storeCurrentRfp(rfp){
    this.currentRfp = rfp;
  }

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

 
  rfpList = [];
  viewRFPDetails(item: any){

  }

}
