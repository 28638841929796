import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  login(data){
   return this.httpMasterService.postService('auth/login', data)
  }

  forgotPassword(data){
    return this.httpMasterService.postService('auth/forgotPassword', data)
   }
}
