import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  getBuyersForAdminApproval(){
    return this.httpMasterService.getService("admin/pending/buyers");
  }

  getApprovedBuyers(){
    return this.httpMasterService.getService("admin/approved/buyers");
  }

  getBuyerRfpsList(){
    return this.httpMasterService.getService("admin/rfpsList");
  }

  getRejectedBuyers(){
    return this.httpMasterService.getService("admin/rejected/buyers");
  }

  getSellersForAdminApproval(){
    return this.httpMasterService.getService("admin/pending/sellers");
  }

  getApprovedSellers(){
    return this.httpMasterService.getService("admin/approved/sellers");
  }

  getRejectedSellers(){
    return this.httpMasterService.getService("admin/rejected/sellers");
  }

  getReviewedSellersList(){
    return this.httpMasterService.getService("admin/reviewed/sellers");
  }

  pauseSeller(data){
    return this.httpMasterService.postService("admin/seller/changeStatus", data);
  }

  deleteSeller(data){
    return this.httpMasterService.postService("admin/delete/seller", data);
  }

  pauseBuyer(data){
    return this.httpMasterService.postService("admin/buyer/changeActiveStatus", data);
  }

  deleteBuyer(data){
    return this.httpMasterService.postService("admin/delete/buyer", data);
  }
}
