import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "./../services/auth.service";
import decode from "jwt-decode";
import { CookieService } from 'angular2-cookie/services/cookies.service';

@Injectable({
  providedIn: "root",
})
export class RoleGuardService implements CanActivate {
  constructor(
    public auth: AuthService, 
    public router: Router,
    private cookiesService: CookieService
    ) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    const token = this.cookiesService.get("gfp_jwt_token");
    if (!token) {
      this.router.navigate(["/login"]);
      return false;
    }
    const tokenPayload = decode(token);
    if (!this.auth.isAuthenticated() || tokenPayload.role !== expectedRole) {
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  }
}
