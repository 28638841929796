import { Component, OnInit } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";
import { PendingsService } from "./../../services/pendings.service";

@Component({
  selector: "app-admin-sidemenu",
  templateUrl: "./admin-sidemenu.component.html",
  styleUrls: ["./admin-sidemenu.component.css"],
})
export class AdminSidemenuComponent implements OnInit {
  constructor(
    private adminService: AdminService,
    private pendingService: PendingsService
  ) {}

  ngOnInit() {
    this.pendingService.onBuyerSellerStatusChange.subscribe((resp) => {
      this.getBuyersForAdminApproval();
    });
    $(document).ready(function () {
      $("div a").click(function () {
        $("a").removeClass("active");
        $(this).addClass("active");
      });
    });
    this.getBuyersForAdminApproval();
  }

  pendingBuyers: number;
  pendingSellers: number;
  totalPendings: number;
  getBuyersForAdminApproval() {
    this.adminService.getBuyersForAdminApproval().subscribe((resp: any) => {
      this.pendingBuyers = resp.data.length;
      this.getSellersForAdminApproval();
    });
  }

  getSellersForAdminApproval() {
    this.adminService.getSellersForAdminApproval().subscribe((resp: any) => {
      this.pendingSellers = resp.data.length;
      this.totalPendings = this.pendingBuyers + this.pendingSellers;
    });
  }
}
