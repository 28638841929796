import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryPhoneCodesService {

  constructor() { }

  getCountryPhoneCode(code: string): string{
    let countryCode;
    let country =  this.countryPhoneCodes.filter((country)=>{
      return code === country.iso2;
    });
    if(country.length === 1){
      countryCode = country[0].phone_code;
    }else{
      countryCode = "";
    }
    return countryCode;
  }

  countryPhoneCodes = [
    {
      "iso2": "AF",
      "phone_code": "+93"
    },
    {
      "iso2": "AX",
      "phone_code": "+358-18"
    },
    {
      "iso2": "AL",
      "phone_code": "+355"
    },
    {
      "iso2": "DZ",
      "phone_code": "+213"
    },
    {
      "iso2": "AS",
      "phone_code": "+1-684"
    },
    {
      "iso2": "AD",
      "phone_code": "+376"
    },
    {
      "iso2": "AO",
      "phone_code": "+244"
    },
    {
      "iso2": "AI",
      "phone_code": "+1-264"
    },
    {
      "iso2": "AQ",
      "phone_code": ""
    },
    {
      "iso2": "AG",
      "phone_code": "+1-268"
    },
    {
      "iso2": "AR",
      "phone_code": "+54"
    },
    {
      "iso2": "AM",
      "phone_code": "+374"
    },
    {
      "iso2": "AW",
      "phone_code": "+297"
    },
    {
      "iso2": "AU",
      "phone_code": "+61"
    },
    {
      "iso2": "AT",
      "phone_code": "+43"
    },
    {
      "iso2": "AZ",
      "phone_code": "+994"
    },
    {
      "iso2": "BS",
      "phone_code": "+1-242"
    },
    {
      "iso2": "BH",
      "phone_code": "+973"
    },
    {
      "iso2": "BD",
      "phone_code": "+880"
    },
    {
      "iso2": "BB",
      "phone_code": "+1-246"
    },
    {
      "iso2": "BY",
      "phone_code": "+375"
    },
    {
      "iso2": "BE",
      "phone_code": "+32"
    },
    {
      "iso2": "BZ",
      "phone_code": "+501"
    },
    {
      "iso2": "BJ",
      "phone_code": "+229"
    },
    {
      "iso2": "BM",
      "phone_code": "+1-441"
    },
    {
      "iso2": "BT",
      "phone_code": "+975"
    },
    {
      "iso2": "BO",
      "phone_code": "+591"
    },
    {
      "iso2": "BA",
      "phone_code": "+387"
    },
    {
      "iso2": "BW",
      "phone_code": "+267"
    },
    {
      "iso2": "BV",
      "phone_code": ""
    },
    {
      "iso2": "BR",
      "phone_code": "+55"
    },
    {
      "iso2": "IO",
      "phone_code": "+246"
    },
    {
      "iso2": "BN",
      "phone_code": "+673"
    },
    {
      "iso2": "BG",
      "phone_code": "+359"
    },
    {
      "iso2": "BF",
      "phone_code": "+226"
    },
    {
      "iso2": "BI",
      "phone_code": "+257"
    },
    {
      "iso2": "KH",
      "phone_code": "+855"
    },
    {
      "iso2": "CM",
      "phone_code": "+237"
    },
    {
      "iso2": "CA",
      "phone_code": "+1"
    },
    {
      "iso2": "CV",
      "phone_code": "+238"
    },
    {
      "iso2": "KY",
      "phone_code": "+1-345"
    },
    {
      "iso2": "CF",
      "phone_code": "+236"
    },
    {
      "iso2": "TD",
      "phone_code": "+235"
    },
    {
      "iso2": "CL",
      "phone_code": "+56"
    },
    {
      "iso2": "CN",
      "phone_code": "+86"
    },
    {
      "iso2": "CX",
      "phone_code": "+61"
    },
    {
      "iso2": "CC",
      "phone_code": "+61"
    },
    {
      "iso2": "CO",
      "phone_code": "+57"
    },
    {
      "iso2": "KM",
      "phone_code": "+269"
    },
    {
      "iso2": "CG",
      "phone_code": "+242"
    },
    {
      "iso2": "CD",
      "phone_code": "+243"
    },
    {
      "iso2": "CK",
      "phone_code": "+682"
    },
    {
      "iso2": "CR",
      "phone_code": "+506"
    },
    {
      "iso2": "CI",
      "phone_code": "+225"
    },
    {
      "iso2": "HR",
      "phone_code": "+385"
    },
    {
      "iso2": "CU",
      "phone_code": "+53"
    },
    {
      "iso2": "CY",
      "phone_code": "+357"
    },
    {
      "iso2": "CZ",
      "phone_code": "+420"
    },
    {
      "iso2": "DK",
      "phone_code": "+45"
    },
    {
      "iso2": "DJ",
      "phone_code": "+253"
    },
    {
      "iso2": "DM",
      "phone_code": "+1-767"
    },
    {
      "iso2": "DO",
      "phone_code": ""
    },
    {
      "iso2": "TL",
      "phone_code": "+670"
    },
    {
      "iso2": "EC",
      "phone_code": "+593"
    },
    {
      "iso2": "EG",
      "phone_code": "+20"
    },
    {
      "iso2": "SV",
      "phone_code": "+503"
    },
    {
      "iso2": "GQ",
      "phone_code": "+240"
    },
    {
      "iso2": "ER",
      "phone_code": "+291"
    },
    {
      "iso2": "EE",
      "phone_code": "+372"
    },
    {
      "iso2": "ET",
      "phone_code": "+251"
    },
    {
      "iso2": "FK",
      "phone_code": "+500"
    },
    {
      "iso2": "FO",
      "phone_code": "+298"
    },
    {
      "iso2": "FJ",
      "phone_code": "+679"
    },
    {
      "iso2": "FI",
      "phone_code": "+358"
    },
    {
      "iso2": "FR",
      "phone_code": "+33"
    },
    {
      "iso2": "GF",
      "phone_code": "+594"
    },
    {
      "iso2": "PF",
      "phone_code": "+689"
    },
    {
      "iso2": "TF",
      "phone_code": ""
    },
    {
      "iso2": "GA",
      "phone_code": "+241"
    },
    {
      "iso2": "GM",
      "phone_code": "+220"
    },
    {
      "iso2": "GE",
      "phone_code": "+995"
    },
    {
      "iso2": "DE",
      "phone_code": "+49"
    },
    {
      "iso2": "GH",
      "phone_code": "+233"
    },
    {
      "iso2": "GI",
      "phone_code": "+350"
    },
    {
      "iso2": "GR",
      "phone_code": "+30"
    },
    {
      "iso2": "GL",
      "phone_code": "+299"
    },
    {
      "iso2": "GD",
      "phone_code": "+1-473"
    },
    {
      "iso2": "GP",
      "phone_code": "+590"
    },
    {
      "iso2": "GU",
      "phone_code": "+1-671"
    },
    {
      "iso2": "GT",
      "phone_code": "+502"
    },
    {
      "iso2": "GG",
      "phone_code": "+44-1481"
    },
    {
      "iso2": "GN",
      "phone_code": "+224"
    },
    {
      "iso2": "GW",
      "phone_code": "+245"
    },
    {
      "iso2": "GY",
      "phone_code": "+592"
    },
    {
      "iso2": "HT",
      "phone_code": "+509"
    },
    {
      "iso2": "HM",
      "phone_code": ""
    },
    {
      "iso2": "HN",
      "phone_code": "+504"
    },
    {
      "iso2": "HK",
      "phone_code": "+852"
    },
    {
      "iso2": "HU",
      "phone_code": "+36"
    },
    {
      "iso2": "IS",
      "phone_code": "+354"
    },
    {
      "iso2": "IN",
      "phone_code": "+91"
    },
    {
      "iso2": "ID",
      "phone_code": "+62"
    },
    {
      "iso2": "IR",
      "phone_code": "+98"
    },
    {
      "iso2": "IQ",
      "phone_code": "+964"
    },
    {
      "iso2": "IE",
      "phone_code": "+353"
    },
    {
      "iso2": "IL",
      "phone_code": "+972"
    },
    {
      "iso2": "IT",
      "phone_code": "+39"
    },
    {
      "iso2": "JM",
      "phone_code": "+1-876"
    },
    {
      "iso2": "JP",
      "phone_code": "+81"
    },
    {
      "iso2": "JE",
      "phone_code": "+44-1534"
    },
    {
      "iso2": "JO",
      "phone_code": "+962"
    },
    {
      "iso2": "KZ",
      "phone_code": "+7"
    },
    {
      "iso2": "KE",
      "phone_code": "+254"
    },
    {
      "iso2": "KI",
      "phone_code": "+686"
    },
    {
      "iso2": "KP",
      "phone_code": "+850"
    },
    {
      "iso2": "KR",
      "phone_code": "+82"
    },
    {
      "iso2": "KW",
      "phone_code": "+965"
    },
    {
      "iso2": "KG",
      "phone_code": "+996"
    },
    {
      "iso2": "LA",
      "phone_code": "+856"
    },
    {
      "iso2": "LV",
      "phone_code": "+371"
    },
    {
      "iso2": "LB",
      "phone_code": "+961"
    },
    {
      "iso2": "LS",
      "phone_code": "+266"
    },
    {
      "iso2": "LR",
      "phone_code": "+231"
    },
    {
      "iso2": "LY",
      "phone_code": "+218"
    },
    {
      "iso2": "LI",
      "phone_code": "+423"
    },
    {
      "iso2": "LT",
      "phone_code": "+370"
    },
    {
      "iso2": "LU",
      "phone_code": "+352"
    },
    {
      "iso2": "MO",
      "phone_code": "+853"
    },
    {
      "iso2": "MK",
      "phone_code": "+389"
    },
    {
      "iso2": "MG",
      "phone_code": "+261"
    },
    {
      "iso2": "MW",
      "phone_code": "+265"
    },
    {
      "iso2": "MY",
      "phone_code": "+60"
    },
    {
      "iso2": "MV",
      "phone_code": "+960"
    },
    {
      "iso2": "ML",
      "phone_code": "+223"
    },
    {
      "iso2": "MT",
      "phone_code": "+356"
    },
    {
      "iso2": "IM",
      "phone_code": "+44-1624"
    },
    {
      "iso2": "MH",
      "phone_code": "+692"
    },
    {
      "iso2": "MQ",
      "phone_code": "+596"
    },
    {
      "iso2": "MR",
      "phone_code": "+222"
    },
    {
      "iso2": "MU",
      "phone_code": "+230"
    },
    {
      "iso2": "YT",
      "phone_code": "+262"
    },
    {
      "iso2": "MX",
      "phone_code": "+52"
    },
    {
      "iso2": "FM",
      "phone_code": "+691"
    },
    {
      "iso2": "MD",
      "phone_code": "+373"
    },
    {
      "iso2": "MC",
      "phone_code": "+377"
    },
    {
      "iso2": "MN",
      "phone_code": "+976"
    },
    {
      "iso2": "ME",
      "phone_code": "+382"
    },
    {
      "iso2": "MS",
      "phone_code": "+1-664"
    },
    {
      "iso2": "MA",
      "phone_code": "+212"
    },
    {
      "iso2": "MZ",
      "phone_code": "+258"
    },
    {
      "iso2": "MM",
      "phone_code": "+95"
    },
    {
      "iso2": "NA",
      "phone_code": "+264"
    },
    {
      "iso2": "NR",
      "phone_code": "+674"
    },
    {
      "iso2": "NP",
      "phone_code": "+977"
    },
    {
      "iso2": "AN",
      "phone_code": ""
    },
    {
      "iso2": "NL",
      "phone_code": "+31"
    },
    {
      "iso2": "NC",
      "phone_code": "+687"
    },
    {
      "iso2": "NZ",
      "phone_code": "+64"
    },
    {
      "iso2": "NI",
      "phone_code": "+505"
    },
    {
      "iso2": "NE",
      "phone_code": "+227"
    },
    {
      "iso2": "NG",
      "phone_code": "+234"
    },
    {
      "iso2": "NU",
      "phone_code": "+683"
    },
    {
      "iso2": "NF",
      "phone_code": "+672"
    },
    {
      "iso2": "MP",
      "phone_code": "+1-670"
    },
    {
      "iso2": "NO",
      "phone_code": "+47"
    },
    {
      "iso2": "OM",
      "phone_code": "+968"
    },
    {
      "iso2": "PK",
      "phone_code": "+92"
    },
    {
      "iso2": "PW",
      "phone_code": "+680"
    },
    {
      "iso2": "PS",
      "phone_code": "+970"
    },
    {
      "iso2": "PA",
      "phone_code": "+507"
    },
    {
      "iso2": "PG",
      "phone_code": "+675"
    },
    {
      "iso2": "PY",
      "phone_code": "+595"
    },
    {
      "iso2": "PE",
      "phone_code": "+51"
    },
    {
      "iso2": "PH",
      "phone_code": "+63"
    },
    {
      "iso2": "PN",
      "phone_code": "+870"
    },
    {
      "iso2": "PL",
      "phone_code": "+48"
    },
    {
      "iso2": "PT",
      "phone_code": "+351"
    },
    {
      "iso2": "PR",
      "phone_code": "+1-787"
    },
    {
      "iso2": "QA",
      "phone_code": "+974"
    },
    {
      "iso2": "RE",
      "phone_code": "+262"
    },
    {
      "iso2": "RO",
      "phone_code": "+40"
    },
    {
      "iso2": "RU",
      "phone_code": "+7"
    },
    {
      "iso2": "RW",
      "phone_code": "+250"
    },
    {
      "iso2": "SH",
      "phone_code": "+290"
    },
    {
      "iso2": "KN",
      "phone_code": "+1-869"
    },
    {
      "iso2": "LC",
      "phone_code": "+1-758"
    },
    {
      "iso2": "PM",
      "phone_code": "+508"
    },
    {
      "iso2": "VC",
      "phone_code": "+1-784"
    },
    {
      "iso2": "BL",
      "phone_code": "+590"
    },
    {
      "iso2": "MF",
      "phone_code": "+590"
    },
    {
      "iso2": "WS",
      "phone_code": "+685"
    },
    {
      "iso2": "SM",
      "phone_code": "+378"
    },
    {
      "iso2": "ST",
      "phone_code": "+239"
    },
    {
      "iso2": "SA",
      "phone_code": "+966"
    },
    {
      "iso2": "SN",
      "phone_code": "+221"
    },
    {
      "iso2": "RS",
      "phone_code": "+381"
    },
    {
      "iso2": "SC",
      "phone_code": "+248"
    },
    {
      "iso2": "SL",
      "phone_code": "+232"
    },
    {
      "iso2": "SG",
      "phone_code": "+65"
    },
    {
      "iso2": "SK",
      "phone_code": "+421"
    },
    {
      "iso2": "SI",
      "phone_code": "+386"
    },
    {
      "iso2": "SB",
      "phone_code": "+677"
    },
    {
      "iso2": "SO",
      "phone_code": "+252"
    },
    {
      "iso2": "ZA",
      "phone_code": "+27"
    },
    {
      "iso2": "GS",
      "phone_code": ""
    },
    {
      "iso2": "SS",
      "phone_code": "+211"
    },
    {
      "iso2": "ES",
      "phone_code": "+34"
    },
    {
      "iso2": "LK",
      "phone_code": "+94"
    },
    {
      "iso2": "SD",
      "phone_code": "+249"
    },
    {
      "iso2": "SR",
      "phone_code": "+597"
    },
    {
      "iso2": "SJ",
      "phone_code": "+47"
    },
    {
      "iso2": "SZ",
      "phone_code": "+268"
    },
    {
      "iso2": "SE",
      "phone_code": "+46"
    },
    {
      "iso2": "CH",
      "phone_code": "+41"
    },
    {
      "iso2": "SY",
      "phone_code": "+963"
    },
    {
      "iso2": "TW",
      "phone_code": "+886"
    },
    {
      "iso2": "TJ",
      "phone_code": "+992"
    },
    {
      "iso2": "TZ",
      "phone_code": "+255"
    },
    {
      "iso2": "TH",
      "phone_code": "+66"
    },
    {
      "iso2": "TG",
      "phone_code": "+228"
    },
    {
      "iso2": "TK",
      "phone_code": "+690"
    },
    {
      "iso2": "TO",
      "phone_code": "+676"
    },
    {
      "iso2": "TT",
      "phone_code": "+1-868"
    },
    {
      "iso2": "TN",
      "phone_code": "+216"
    },
    {
      "iso2": "TR",
      "phone_code": "+90"
    },
    {
      "iso2": "TM",
      "phone_code": "+993"
    },
    {
      "iso2": "TC",
      "phone_code": "+1-649"
    },
    {
      "iso2": "TV",
      "phone_code": "+688"
    },
    {
      "iso2": "UG",
      "phone_code": "+256"
    },
    {
      "iso2": "UA",
      "phone_code": "+380"
    },
    {
      "iso2": "AE",
      "phone_code": "+971"
    },
    {
      "iso2": "GB",
      "phone_code": "+44"
    },
    {
      "iso2": "US",
      "phone_code": "+1"
    },
    {
      "iso2": "UM",
      "phone_code": "+1"
    },
    {
      "iso2": "UY",
      "phone_code": "+598"
    },
    {
      "iso2": "UZ",
      "phone_code": "+998"
    },
    {
      "iso2": "VU",
      "phone_code": "+678"
    },
    {
      "iso2": "VA",
      "phone_code": "+379"
    },
    {
      "iso2": "VE",
      "phone_code": "+58"
    },
    {
      "iso2": "VN",
      "phone_code": "+84"
    },
    {
      "iso2": "VG",
      "phone_code": "+1-284"
    },
    {
      "iso2": "VI",
      "phone_code": "+1-340"
    },
    {
      "iso2": "WF",
      "phone_code": "+681"
    },
    {
      "iso2": "EH",
      "phone_code": "+212"
    },
    {
      "iso2": "YE",
      "phone_code": "+967"
    },
    {
      "iso2": "ZM",
      "phone_code": "+260"
    },
    {
      "iso2": "ZW",
      "phone_code": "+263"
    }
  ]
}
