// Angular modules
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatToolbarModule, MatTooltipModule } from "@angular/material";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TooltipModule } from 'ng2-tooltip-directive';

// 3rd part modules
import { ToastrModule } from "ngx-toastr";
import { JwtModule } from "@auth0/angular-jwt";
import { DataTablesModule } from "angular-datatables";
import { AppComponent } from "./app.component";
import { AgmCoreModule } from "@agm/core";
import { Ng2AutoCompleteModule } from "ng2-auto-complete";
import { PreventDoubleSubmitModule } from "ngx-prevent-double-submission";
import { NgxSpinnerModule } from "ngx-spinner";

// App modules
import { AppRoutingModule } from "./app-routing.module";

// Services
import { CookieService } from "angular2-cookie/services/cookies.service";

// Components, Directives and Pipes
import { HeaderComponent } from "./Components/header/header.component";
import { LoginComponent } from "./Components/login/login.component";
import { LandingComponent } from "./Components/landing/landing.component";
import { SignUpComponent } from "./Components/sign-up/sign-up.component";
import { FindSellerComponent } from "./Components/find-seller/find-seller.component";
import { BuyerLandingComponent } from "./Components/buyer-landing/buyer-landing.component";
import { SellerLandingComponent } from "./Components/seller-landing/seller-landing.component";
import { AdminLandingComponent } from "./Components/admin-landing/admin-landing.component";
import { SellerDetailsComponent } from "./Components/seller-details/seller-details.component";
import { WaitingForApprovalComponent } from "./Components/waiting-for-approval/waiting-for-approval.component";
import { MustMatchDirective } from "./directives/must-match.directive";
import { SellerProductsComponent } from "./Components/seller-products/seller-products.component";
import { FooterComponent } from "./Components/footer/footer.component";
import { CreateSellerComponent } from "./Components/create-seller/create-seller.component";
import { ErrorInterceptorService } from "./interceptors/error-interceptor.service";
import { AuthInterceptorService } from "./interceptors/auth-interceptor.service";
import { AdminSidemenuComponent } from "./Components/admin-sidemenu/admin-sidemenu.component";
import { SellerProductsApprovalsComponent } from "./Components/seller-products-approvals/seller-products-approvals.component";
import { BuyerApprovalsComponent } from "./Components/buyer-approvals/buyer-approvals.component";
import { SellerProductsRejectedComponent } from "./Components/seller-products-rejected/seller-products-rejected.component";
import { BuyerRejectedComponent } from "./Components/buyer-rejected/buyer-rejected.component";
import { ProfileDetailsComponent } from "./Components/profile-details/profile-details.component";
import { PendingBuyersComponent } from "./Components/pending-buyers/pending-buyers.component";
import { PendingSellerProductsComponent } from "./Components/pending-seller-products/pending-seller-products.component";
import { ErrorComponent } from "./Components/error/error.component";
import { UnAuthorizedComponent } from "./un-authorized/un-authorized.component";
import { DebounceClickDirective } from "./directives/debounce-click.directive";
import { BuyerRfpComponent } from './Components/buyer-rfp/buyer-rfp.component';
import { BuyerRfpListComponent } from './Components/buyer-rfp-list/buyer-rfp-list.component';
import { SellerRegistrationComponent } from './Components/seller-registration/seller-registration.component';
import { BuyerRegistrationComponent } from './Components/buyer-registration/buyer-registration.component';
import { BuyerApprovalRequestComponent } from './Components/buyer-approval-request/buyer-approval-request.component';
import { SellerProductInfoComponent } from './Components/seller-product-info/seller-product-info.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { BuyerProfileComponent } from './Components/buyer-profile/buyer-profile.component';
import { SellerDetailsPreviewComponent } from './Components/seller-details-preview/seller-details-preview.component';
import {CarouselModule} from 'primeng/carousel';
import { AdminFooterComponent } from './Components/admin-footer/admin-footer.component';
import { TearmsAndConditionsComponent } from './Components/tearms-and-conditions/tearms-and-conditions.component';
import { ReviewedSellersComponent } from './Components/reviewed-sellers/reviewed-sellers.component';
import { HomePageComponent } from './Components/home-page/home-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    LandingComponent,
    SignUpComponent,
    FindSellerComponent,
    BuyerLandingComponent,
    SellerLandingComponent,
    AdminLandingComponent,
    SellerDetailsComponent,
    WaitingForApprovalComponent,
    MustMatchDirective,
    SellerProductsComponent,
    FooterComponent,
    CreateSellerComponent,
    AdminSidemenuComponent,
    SellerProductsApprovalsComponent,
    BuyerApprovalsComponent,
    SellerProductsRejectedComponent,
    BuyerRejectedComponent,
    ProfileDetailsComponent,
    PendingBuyersComponent,
    PendingSellerProductsComponent,
    ErrorComponent,
    UnAuthorizedComponent,
    DebounceClickDirective,
    BuyerRfpComponent,
    BuyerRfpListComponent,
    SellerRegistrationComponent,
    BuyerRegistrationComponent,
    BuyerApprovalRequestComponent,
    SellerProductInfoComponent,
    BuyerProfileComponent,
    SellerDetailsPreviewComponent,
    AdminFooterComponent,
    TearmsAndConditionsComponent,
    ReviewedSellersComponent,
    HomePageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    TooltipModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTooltipModule,
    DataTablesModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBWrBonT53rpTnJccQu7s5T4vo5NAwp1e0",
    }),
    HttpClientModule,
    Ng2AutoCompleteModule,
    PreventDoubleSubmitModule.forRoot(),
    NgxSpinnerModule,
    MultiSelectModule,
    AutoCompleteModule,
    DropdownModule,
    AgmJsMarkerClustererModule,
    CarouselModule
  ],
  providers: [
    { provide: CookieService, useFactory: cookieServiceFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function cookieServiceFactory() {
  return new CookieService();
}

export function tokenGetter() {
  return this.cookiesService.get("gfp_jwt_token");
}
