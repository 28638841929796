import { Injectable } from '@angular/core';

import { HttpMasterService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  getCountries(){
    return this.httpMasterService.getService('masterData/countries')
  }

  getRoles(){
    return this.httpMasterService.getService('masterData/signUpRoles')
  }

  getLegalStatuses(){
    return this.httpMasterService.getService('masterData/legalStatuses')
  }

  getCities(state: string){
    return this.httpMasterService.getService(`masterData/cities/${state}`)
  }

  getStates(country: string){
    return this.httpMasterService.getService(`masterData/states/${country}`)
  }

  getCategories(){
    return this.httpMasterService.getService('masterData/categories')
  }

  getSubcategoriesByCategory(id){
    return this.httpMasterService.getService(`masterData/subcategoriesByCategory/${id}`)
  }
  
  getBuyerNatureOfBusinessList(){
    return this.httpMasterService.getService(`masterData/buyers/natureOfBusinessList`)
  }
  getSellerNatureOfBusinessList(){
    return this.httpMasterService.getService(`masterData/sellers/natureOfBusinessList`)
  }
  getSellerNatureOfBusinessListSubTypes(){
    return this.httpMasterService.getService(`masterData/sellers/natureOfBusinessListSubTypes`)
  }
  getCFPSystems(){
    return this.httpMasterService.getService(`masterData/cfps`)
  }

}
