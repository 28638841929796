import { Injectable } from '@angular/core';
import { HttpMasterService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  // signUp(data){
  //   return this.httpMasterService.postService('auth/signUp', data)
  // }
  buyerRegistration(data){
    return this.httpMasterService.postService('auth/buyer', data)
  }
  sellerRegistration(data){
    return this.httpMasterService.postService('auth/seller', data)
  }
  chechUserAlreadyExist(email){
    return this.httpMasterService.getService(`auth/checkIsUserAlreadyExist/${email}`)
  }
}
