import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor() {
    $("#scroll").click(function() {
      $('html,body').animate({
          scrollTop: $(".second").offset().top},
          'slow');
  });
   }

  ngOnInit() {
    $("#scroll").click(function() {
      $('html,body').animate({
          scrollTop: $(".second").offset().top},
          'slow');
  });
  }

}
