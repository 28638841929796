import { Component, OnInit } from "@angular/core";
import { MasterDataService } from "src/app/services/master-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { CountryPhoneCodesService } from "src/app/services/country-phone-codes.service";
import { SignUpService } from "src/app/services/sign-up.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-seller-registration",
  templateUrl: "./seller-registration.component.html",
  styleUrls: ["./seller-registration.component.css"],
})
export class SellerRegistrationComponent implements OnInit {
  show_password: Boolean = false;
  show_confirm_password: Boolean = false;

  constructor(
    private masterDataService: MasterDataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private countryCodesService: CountryPhoneCodesService,
    private signUpService: SignUpService
  ) {}

  natureOfBusinessList: any = [];
  selectedBusinessList: any = [];
  selectedBusinessListSubTypes: any = [];

  ngOnInit() {
    this.getCountries();
    this.getNatureOfBusinessList();
    this.getNatureOfBusinessListSubTypes();
  }
  
 showPassword() {
     this.show_password = !this.show_password;
   }
   showConfirmPassword() {
    this.show_confirm_password = !this.show_confirm_password;
  }

  getNatureOfBusinessList() {
    this.spinner.show();
    this.masterDataService.getSellerNatureOfBusinessList().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.natureOfBusinessList = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  natureOfBusinessListSubTypes: any = []
  getNatureOfBusinessListSubTypes() {
    this.spinner.show();
    this.masterDataService.getSellerNatureOfBusinessListSubTypes().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.natureOfBusinessListSubTypes = resp.data;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
      }
    );
  }

  countries: any = [];
  selectedCountry: any;
  country_phone_code: string;
  registrationModel: any = {};
  acceptTAndC = true;

  getCountryPhoneCode() {
    this.country_phone_code = this.countryCodesService.getCountryPhoneCode(
      this.registrationModel.country
    );
  }

  getCountries() {
    this.spinner.show();
    this.masterDataService.getCountries().subscribe(
      (data: any) => {
        this.spinner.hide();
        this.countries = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  cities: any = [];
  selectedCity: any;
  getCities() {
    this.spinner.show();
    this.masterDataService.getCities(this.registrationModel.country).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.cities = data.data;
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.error.message);
        if (err.status === 401) {
          this.router.navigate["/login"];
        } else if (err.status === 500) {
          this.router.navigate["/error"];
        }
      }
    );
  }

  isCountrySelected = false;
  isCitySelected = false;
  validateSelectedCountry() {
    if (this.registrationModel.country) {
      this.isCountrySelected = true;
    } else {
      this.isCountrySelected = false;
    }
  }

  countriesListFormatter(data) {
    return data.country;
  }

  countrySelected(data) {
    this.registrationModel.country = data.country_code;
    this.getCities();
    this.getCountryPhoneCode();
  }

  clerPreviousSelectedCountry() {
    this.registrationModel.country = "";
    this.cities = [];
    this.selectedCity = "";
    this.registrationModel.city = "";
  }

  validateSelectedCity() {
    if (this.registrationModel.city) {
      this.isCitySelected = true;
    } else {
      this.isCitySelected = false;
    }
  }

  citiesListFormatter(data) {
    return data.city;
  }

  citySelected(data) {
    this.registrationModel.city = data.id;
  }

  clerPreviousSelectedCity() {
    this.registrationModel.city = "";
  }

  onBusinessListValueChange(){
    if(this.selectedBusinessList.indexOf(3) < 0){
      this.registrationModel.nature_of_business_list_otherName = null;
    }
    if(this.selectedBusinessList.indexOf(2) < 0){
      this.selectedBusinessListSubTypes = [];
    }
  }

  onBusinessListSubTypeValueChange(){
    if(this.selectedBusinessList.indexOf(4) < 0){
      this.registrationModel.nature_of_business_list_sub_type_otherName = null;
    }
  }

  isButtonDisabled = false;
  sellerSignUpFormSubmit() {
    this.spinner.show();
    this.isButtonDisabled = true;
    const dataToSubmit = {
      ...this.registrationModel,
      nature_of_business_list: this.selectedBusinessList,
      nature_of_business_list_sub_types: this.selectedBusinessListSubTypes,
      role: 2,
    };
    this.signUpService.sellerRegistration(dataToSubmit).subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.toastr.success(resp.message);
        this.router.navigate(["/sellerRegistrationSuccess"]);
      },
      (err) => {
        this.spinner.hide();
        this.isButtonDisabled = false;
        this.toastr.error(err.error.message);
      }
    );
  }

  isUserAlreadyExist = false;
  checkIsUserAlreadyExist() {
    if (this.registrationModel.email)
      this.signUpService
        .chechUserAlreadyExist(this.registrationModel.email)
        .subscribe(
          (resp: any) => {
            if (resp.data.isUserAlreadyExist) {
              this.isUserAlreadyExist = true;
            } else {
              this.isUserAlreadyExist = false;
            }
          },
          (err) => {
            this.toastr.error(err.error.message);
          }
        );
  }

  alphabetsAndSpaceOnly(e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32
    ) {
      return true;
    }
    return false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
