import { Injectable } from "@angular/core";
import { HttpMasterService } from "./http.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class PendingsService {
  constructor(private httpMasterService: HttpMasterService) {}

  onBuyerSellerStatusChange: Subject<string> = new Subject();
  approveBuyer(data) {
    return this.httpMasterService.postService("admin/approve/buyer", data);
  }

  rejectBuyer(data) {
    return this.httpMasterService.postService("admin/reject/buyer", data);
  }

  approveSeller(data) {
    return this.httpMasterService.postService("admin/approve/seller", data);
  }

  rejectSeller(data) {
    return this.httpMasterService.postService("admin/reject/seller", data);
  }

  sellerSubmitForModification(data) {
    return this.httpMasterService.postService("admin/rework/seller", data);
  }
}
